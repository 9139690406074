import React from 'react';
import { Domain } from '../../types/domain';

interface DomainCardProps {
  domain: Domain;
}

const DomainCard: React.FC<DomainCardProps> = ({ domain }) => {
  const handleBid = () => {
    // Implement bidding logic
    console.log('Placing bid for domain:', domain.id);
  };

  const handleBuyNow = () => {
    // Implement immediate purchase logic
    console.log('Purchasing domain:', domain.id);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-2">{domain.name}</h3>
      <p className="text-gray-600 mb-4">{domain.description}</p>
      <div className="space-y-2 mb-4">
        <div className="flex justify-between">
          <span className="text-gray-600">Price:</span>
          <span className="font-semibold">${domain.price.toLocaleString()}</span>
        </div>
        {domain.traffic && (
          <div className="flex justify-between">
            <span className="text-gray-600">Monthly Traffic:</span>
            <span>{domain.traffic.toLocaleString()}</span>
          </div>
        )}
        {domain.age && (
          <div className="flex justify-between">
            <span className="text-gray-600">Domain Age:</span>
            <span>{domain.age} years</span>
          </div>
        )}
        <div className="flex justify-between">
          <span className="text-gray-600">Seller:</span>
          <span>{domain.seller}</span>
        </div>
      </div>
      <div className="flex gap-2">
        <button
          onClick={handleBid}
          className="flex-1 bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition duration-300"
        >
          Place Bid
        </button>
        <button
          onClick={handleBuyNow}
          className="flex-1 bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-300"
        >
          Buy Now
        </button>
      </div>
    </div>
  );
};

export default DomainCard;