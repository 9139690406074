import React, { useState } from 'react';
import { Upload, DollarSign } from 'lucide-react';

interface NFTListingFormProps {
  onSubmit: (nftData: NFTData) => void;
}

interface NFTData {
  title: string;
  description: string;
  price: number;
  image: File | null;
}

const NFTListingForm: React.FC<NFTListingFormProps> = ({ onSubmit }) => {
  const [nftData, setNftData] = useState<NFTData>({
    title: '',
    description: '',
    price: 0,
    image: null,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNftData({ ...nftData, [name]: value });
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setNftData({ ...nftData, image: e.target.files[0] });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(nftData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="title" className="block text-sm font-medium text-gray-700">
          NFT Title
        </label>
        <input
          type="text"
          id="title"
          name="title"
          value={nftData.title}
          onChange={handleInputChange}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
        />
      </div>
      <div>
        <label htmlFor="description" className="block text-sm font-medium text-gray-700">
          Description
        </label>
        <textarea
          id="description"
          name="description"
          value={nftData.description}
          onChange={handleInputChange}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
        />
      </div>
      <div>
        <label htmlFor="price" className="block text-sm font-medium text-gray-700">
          Price (ETH)
        </label>
        <div className="relative mt-1 rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <DollarSign className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="number"
            id="price"
            name="price"
            value={nftData.price}
            onChange={handleInputChange}
            min="0"
            step="0.01"
            required
            className="block w-full rounded-md border-gray-300 pl-10 focus:border-purple-500 focus:ring-purple-500"
          />
        </div>
      </div>
      <div>
        <label htmlFor="image" className="block text-sm font-medium text-gray-700">
          NFT Image
        </label>
        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
          <div className="space-y-1 text-center">
            <Upload className="mx-auto h-12 w-12 text-gray-400" />
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor="image"
                className="relative cursor-pointer bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-purple-500"
              >
                <span>Upload a file</span>
                <input id="image" name="image" type="file" className="sr-only" onChange={handleImageUpload} />
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
          </div>
        </div>
      </div>
      <div>
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
        >
          List NFT for Sale
        </button>
      </div>
    </form>
  );
};

export default NFTListingForm;