import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import InvestmentPage from './pages/InvestmentPage';
import AdultContentPage from './pages/AdultContentPage';
import LiveCamsPage from './pages/LiveCamsPage';
import ModelSignUpPage from './pages/ModelSignUpPage';
import GabrielleGrowthPage from './pages/GabrielleGrowthPage';
import NewsletterPage from './pages/NewsletterPage';
import UserDashboard from './pages/UserDashboard';
import ShopPage from './pages/ShopPage';
import GamePage from './pages/GamePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import AdListingsPage from './pages/AdListingsPage';
import ForumPage from './pages/ForumPage';
import DomainMarketplacePage from './pages/DomainMarketplacePage';
import AgeGate from './components/AgeGate';
import AudienceQuestionnaire from './components/AudienceQuestionnaire';
import LiveChat from './components/LiveChat';

const App: React.FC = () => {
  const [isVerified, setIsVerified] = useState<boolean | null>(null);
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const verified = localStorage.getItem('age_verified');
    setIsVerified(verified === 'true');

    const questionnaireCompleted = localStorage.getItem('questionnaire_completed');
    if (verified === 'true' && !questionnaireCompleted) {
      setShowQuestionnaire(true);
    }
  }, []);

  const handleVerification = (verified: boolean) => {
    setIsVerified(verified);
    if (verified) {
      setShowQuestionnaire(true);
    }
  };

  const handleQuestionnaireComplete = () => {
    setShowQuestionnaire(false);
  };

  if (isVerified === null) {
    return null;
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      {!isVerified && <AgeGate onVerification={handleVerification} />}
      {showQuestionnaire && <AudienceQuestionnaire onComplete={handleQuestionnaireComplete} />}
      
      <Header />
      <main className="flex-grow container mx-auto px-4 py-8">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/investment" element={<InvestmentPage />} />
          <Route path="/adult-content" element={<AdultContentPage />} />
          <Route path="/live-cams" element={<LiveCamsPage />} />
          <Route path="/model-signup" element={<ModelSignUpPage />} />
          <Route path="/gabrielle-growth" element={<GabrielleGrowthPage />} />
          <Route path="/newsletter" element={<NewsletterPage />} />
          <Route path="/dashboard" element={<UserDashboard />} />
          <Route path="/shop" element={<ShopPage />} />
          <Route path="/game" element={<GamePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-of-service" element={<TermsOfServicePage />} />
          <Route path="/ad-listings" element={<AdListingsPage />} />
          <Route path="/forum" element={<ForumPage />} />
          <Route path="/domains" element={<DomainMarketplacePage />} />
        </Routes>
      </main>
      <LiveChat />
      <Footer />
    </div>
  );
};

export default App;