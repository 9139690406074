import React from 'react';
import { Link } from 'react-router-dom';
import { Instagram, Twitter, Facebook, Youtube, Mail, Phone, MapPin, Sparkles } from 'lucide-react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-900 text-white">
      <div className="container mx-auto px-4 py-12">
        {/* Beta Notice */}
        <div className="text-center mb-8 bg-gradient-to-r from-purple-900 to-pink-900 p-4 rounded-lg">
          <div className="flex items-center justify-center mb-2">
            <Sparkles className="text-yellow-400 mr-2" />
            <span className="font-semibold">Soft Launch Beta</span>
            <Sparkles className="text-yellow-400 ml-2" />
          </div>
          <p className="text-sm text-gray-300">
            Join our early access program and help shape the future of Studio Mystique
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div>
            <h3 className="text-2xl font-bold mb-4 font-audiowide gradient-text">
              Studio Mystique
              <span className="ml-2 text-xs bg-gradient-to-r from-purple-600 to-pink-600 px-2 py-1 rounded-full">Beta</span>
            </h3>
            <p className="text-gray-400 mb-4">
              Empowering adult content creators with comprehensive management services and investment opportunities.
            </p>
            <div className="flex space-x-4">
              <a href="#" className="text-gray-400 hover:text-purple-500 transition-colors">
                <Instagram />
              </a>
              <a href="#" className="text-gray-400 hover:text-purple-500 transition-colors">
                <Twitter />
              </a>
              <a href="#" className="text-gray-400 hover:text-purple-500 transition-colors">
                <Youtube />
              </a>
            </div>
          </div>

          <div>
            <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="text-gray-400 hover:text-purple-500 transition-colors">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" className="text-gray-400 hover:text-purple-500 transition-colors">
                  About
                </Link>
              </li>
              <li>
                <Link to="/investment" className="text-gray-400 hover:text-purple-500 transition-colors">
                  Investment <span className="text-xs bg-purple-600/50 px-1.5 rounded">Beta</span>
                </Link>
              </li>
              <li>
                <Link to="/adult-content" className="text-gray-400 hover:text-purple-500 transition-colors">
                  Adult Content
                </Link>
              </li>
              <li>
                <Link to="/model-signup" className="text-gray-400 hover:text-purple-500 transition-colors">
                  Model Sign Up <span className="text-xs bg-purple-600/50 px-1.5 rounded">Beta</span>
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-lg font-semibold mb-4">Legal</h4>
            <ul className="space-y-2">
              <li>
                <Link to="/privacy-policy" className="text-gray-400 hover:text-purple-500 transition-colors">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms-of-service" className="text-gray-400 hover:text-purple-500 transition-colors">
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link to="/dmca" className="text-gray-400 hover:text-purple-500 transition-colors">
                  DMCA
                </Link>
              </li>
              <li>
                <Link to="/2257" className="text-gray-400 hover:text-purple-500 transition-colors">
                  2257 Statement
                </Link>
              </li>
              <li>
                <Link to="/compliance" className="text-gray-400 hover:text-purple-500 transition-colors">
                  Compliance
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-lg font-semibold mb-4">Contact Us</h4>
            <ul className="space-y-4">
              <li className="flex items-center">
                <Mail className="mr-2 text-purple-500" size={20} />
                <a href="mailto:support@studiomystique.com" className="text-gray-400 hover:text-purple-500 transition-colors">
                  support@studiomystique.com
                </a>
              </li>
              <li className="flex items-center">
                <Phone className="mr-2 text-purple-500" size={20} />
                <a href="tel:+1234567890" className="text-gray-400 hover:text-purple-500 transition-colors">
                  +1 (234) 567-890
                </a>
              </li>
              <li className="flex items-center">
                <MapPin className="mr-2 text-purple-500" size={20} />
                <span className="text-gray-400">
                  Los Angeles, CA
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-gray-800">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-400 text-sm">
              &copy; {new Date().getFullYear()} Studio Mystique LLC. All rights reserved.
            </p>
            <div className="mt-4 md:mt-0">
              <p className="text-gray-400 text-sm">
                Must be 18+ to access this site. Please read our terms of service.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;