import React from 'react';
import { ExternalLink } from 'lucide-react';

const AdListingsPage: React.FC = () => {
  const adLinks = [
    { name: 'OnlyFans', url: 'https://onlyfans.com/' },
    { name: 'Chaturbate', url: 'https://chaturbate.com/' },
    { name: 'Pornhub', url: 'https://www.pornhub.com/' },
    { name: 'XVideos', url: 'https://www.xvideos.com/' },
    { name: 'MyFreeCams', url: 'https://www.myfreecams.com/' },
    { name: 'AdultFriendFinder', url: 'https://adultfriendfinder.com/' },
    { name: 'Fleshlight', url: 'https://www.fleshlight.com/' },
    { name: 'Adam & Eve', url: 'https://www.adameve.com/' },
    { name: 'Lovense', url: 'https://www.lovense.com/' },
    { name: 'CamSoda', url: 'https://www.camsoda.com/' },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Sponsored Links and Advertisements</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {adLinks.map((link, index) => (
          <a
            key={index}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition duration-300 flex items-center justify-between"
          >
            <span>{link.name}</span>
            <ExternalLink size={20} className="text-purple-600" />
          </a>
        ))}
      </div>
      
      {/* Large ad placeholder */}
      <div className="mt-12 bg-gray-200 p-12 rounded-lg text-center">
        <p className="text-2xl text-gray-600">Premium Advertisement Placement</p>
        <p className="mt-4 text-gray-500">Contact us to feature your ad here</p>
      </div>
    </div>
  );
};

export default AdListingsPage;