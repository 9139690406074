import React from 'react';
import { Link } from 'react-router-dom';

const ModelFunding: React.FC = () => {
  return (
    <div className="bg-white p-8 rounded-lg shadow-md mb-12">
      <h2 className="text-3xl font-bold mb-4 text-purple-800">Support Your Favorite Talent</h2>
      <p className="text-lg mb-4">
        Invest directly in the careers of adult content creators and share in their success. Our model funding initiative allows you to support rising stars and established performers alike.
      </p>
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">How It Works</h3>
        <ol className="list-decimal list-inside">
          <li>Choose a model or creator to support</li>
          <li>Select your investment amount</li>
          <li>Receive updates on their career progress</li>
          <li>Earn returns based on their performance and growth</li>
        </ol>
      </div>
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Benefits</h3>
        <ul className="list-disc list-inside">
          <li>Direct impact on a creator's career</li>
          <li>Potential for high returns as creators grow their audience</li>
          <li>Exclusive content and perks from supported models</li>
          <li>Diversify your portfolio with talent investments</li>
        </ul>
      </div>
      <Link to="/model-funding" className="bg-purple-600 text-white px-6 py-3 rounded-full hover:bg-purple-700 transition duration-300">
        Invest in Talent
      </Link>
    </div>
  );
};

export default ModelFunding;