import React from 'react';
import { Star } from 'lucide-react';

interface UserReviewProps {
  name: string;
  role: string;
  review: string;
}

const UserReview: React.FC<UserReviewProps> = ({ name, role, review }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex items-center mb-4">
        <Star className="text-yellow-400" size={20} />
        <Star className="text-yellow-400" size={20} />
        <Star className="text-yellow-400" size={20} />
        <Star className="text-yellow-400" size={20} />
        <Star className="text-yellow-400" size={20} />
      </div>
      <p className="text-gray-700 mb-4">"{review}"</p>
      <div className="flex items-center">
        <div className="bg-purple-100 rounded-full w-12 h-12 flex items-center justify-center mr-4">
          <span className="text-purple-600 font-semibold text-lg">{name.charAt(0)}</span>
        </div>
        <div>
          <h4 className="font-semibold">{name}</h4>
          <p className="text-sm text-gray-600">{role}</p>
        </div>
      </div>
    </div>
  );
};

export default UserReview;