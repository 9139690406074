import React from 'react';
import AdScript from './AdScript';

interface NativeAdProps {
  adId: string;
}

const NativeAd: React.FC<NativeAdProps> = ({ adId }) => {
  return (
    <div>
      <AdScript 
        src="https://js.juicyads.com/juicyads.native-ads.min.js"
        id={`native-script-${adId}`}
      />
      <div
        data-id="juicyads-native-ads"
        data-ad-zone={adId}
        data-targets="a"
      />
    </div>
  );
};

export default NativeAd;