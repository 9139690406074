import React, { useState } from 'react';
import { Search, Filter, Star } from 'lucide-react';
import { ForumMember } from '../../types/forum';

const ForumMembers: React.FC = () => {
  const [members] = useState<ForumMember[]>([
    {
      id: '1',
      name: 'Liliana G.',
      avatar: 'https://via.placeholder.com/100',
      role: 'Model',
      joinDate: '2024-01-01',
      posts: 45,
      followers: 1200,
      verified: true
    },
    // Add more members
  ]);

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div className="relative flex-grow max-w-md">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          <input
            type="text"
            placeholder="Search members..."
            className="w-full pl-10 pr-4 py-2 border rounded-lg"
          />
        </div>
        <button className="flex items-center text-gray-600 hover:text-gray-800 ml-4">
          <Filter size={20} className="mr-2" />
          Filter
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {members.map(member => (
          <div key={member.id} className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex items-center mb-4">
              <img
                src={member.avatar}
                alt={member.name}
                className="w-16 h-16 rounded-full mr-4"
              />
              <div>
                <div className="flex items-center">
                  <h3 className="font-semibold text-lg">{member.name}</h3>
                  {member.verified && (
                    <Star className="ml-1 text-yellow-500" size={16} />
                  )}
                </div>
                <p className="text-sm text-gray-500">{member.role}</p>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4 mb-4 text-center">
              <div>
                <p className="font-semibold">{member.posts}</p>
                <p className="text-sm text-gray-500">Posts</p>
              </div>
              <div>
                <p className="font-semibold">{member.followers}</p>
                <p className="text-sm text-gray-500">Followers</p>
              </div>
              <div>
                <p className="font-semibold">
                  {new Date(member.joinDate).toLocaleDateString(undefined, { year: 'numeric', month: 'short' })}
                </p>
                <p className="text-sm text-gray-500">Joined</p>
              </div>
            </div>
            <button className="w-full bg-purple-600 text-white px-4 py-2 rounded-full hover:bg-purple-700 transition duration-300">
              Follow
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ForumMembers;