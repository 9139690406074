export const handleAdError = (error: Error, adType: string): void => {
  // Log error but prevent it from breaking the app
  console.warn(`Ad error (${adType}):`, error.message);
  
  // Send error to analytics if available
  if (window.gtag) {
    window.gtag('event', 'ad_error', {
      error_type: error.name,
      error_message: error.message,
      ad_type: adType
    });
  }
};

export const isAdBlockerEnabled = async (): Promise<boolean> => {
  try {
    const testAd = document.createElement('div');
    testAd.innerHTML = '&nbsp;';
    testAd.className = 'adsbox';
    document.body.appendChild(testAd);
    const isBlocked = testAd.offsetHeight === 0;
    document.body.removeChild(testAd);
    return isBlocked;
  } catch {
    return true;
  }
};