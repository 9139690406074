import React, { useState, useEffect } from 'react';
import { AlertTriangle, CheckCircle, XCircle } from 'lucide-react';

interface FraudAlert {
  id: string;
  franchiseId: string;
  franchiseName: string;
  description: string;
  severity: 'low' | 'medium' | 'high';
  timestamp: string;
}

const FraudDetectionSystem: React.FC = () => {
  const [fraudAlerts, setFraudAlerts] = useState<FraudAlert[]>([]);

  useEffect(() => {
    // In a real application, this would fetch data from an API
    const mockAlerts: FraudAlert[] = [
      {
        id: '1',
        franchiseId: '1',
        franchiseName: 'Franchise A',
        description: 'Unusual spike in transaction volume',
        severity: 'medium',
        timestamp: '2024-03-20T14:30:00Z',
      },
      {
        id: '2',
        franchiseId: '2',
        franchiseName: 'White Label B',
        description: 'Multiple failed login attempts',
        severity: 'low',
        timestamp: '2024-03-21T09:15:00Z',
      },
      {
        id: '3',
        franchiseId: '3',
        franchiseName: 'Franchise C',
        description: 'Suspicious IP address detected',
        severity: 'high',
        timestamp: '2024-03-22T18:45:00Z',
      },
    ];
    setFraudAlerts(mockAlerts);
  }, []);

  const handleResolveAlert = (id: string) => {
    setFraudAlerts(fraudAlerts.filter(alert => alert.id !== id));
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Fraud Detection System</h2>
      {fraudAlerts.length === 0 ? (
        <p className="text-green-600 flex items-center">
          <CheckCircle size={20} className="mr-2" />
          No active fraud alerts
        </p>
      ) : (
        <div className="space-y-4">
          {fraudAlerts.map(alert => (
            <div key={alert.id} className="border p-4 rounded-lg">
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-lg font-semibold">{alert.franchiseName}</h3>
                <span className={`px-2 py-1 rounded-full text-sm ${
                  alert.severity === 'low' ? 'bg-yellow-200 text-yellow-800' :
                  alert.severity === 'medium' ? 'bg-orange-200 text-orange-800' :
                  'bg-red-200 text-red-800'
                }`}>
                  {alert.severity.charAt(0).toUpperCase() + alert.severity.slice(1)} Severity
                </span>
              </div>
              <p className="text-sm text-gray-600 mb-2">{alert.description}</p>
              <p className="text-xs text-gray-500 mb-4">Detected: {new Date(alert.timestamp).toLocaleString()}</p>
              <div className="flex justify-end">
                <button
                  onClick={() => handleResolveAlert(alert.id)}
                  className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition duration-300 flex items-center"
                >
                  <CheckCircle size={16} className="mr-2" />
                  Resolve Alert
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FraudDetectionSystem;