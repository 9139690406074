import React from 'react';
import { Link } from 'react-router-dom';
import { Users, Target } from 'lucide-react';

const CrowdfundingOpportunities: React.FC = () => {
  return (
    <div className="bg-white p-8 rounded-lg shadow-md mb-12">
      <h2 className="text-3xl font-bold mb-4 text-purple-800">Crowdfunding Opportunities</h2>
      <p className="text-lg mb-6">
        Join forces with other investors to fund exciting projects and businesses. Our crowdfunding platform allows you to invest in a wide range of opportunities with lower minimum investments.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-6">
        <div className="flex items-start">
          <Users className="mr-4 text-purple-600" size={24} />
          <div>
            <h3 className="text-xl font-semibold mb-2">Startup Funding</h3>
            <p>Support innovative startups in the adult entertainment industry and related technologies.</p>
          </div>
        </div>
        <div className="flex items-start">
          <Target className="mr-4 text-purple-600" size={24} />
          <div>
            <h3 className="text-xl font-semibold mb-2">Project-Based Investments</h3>
            <p>Invest in specific projects like film productions, event series, or product launches.</p>
          </div>
        </div>
      </div>
      <Link to="/crowdfunding-opportunities" className="bg-purple-600 text-white px-6 py-3 rounded-full hover:bg-purple-700 transition duration-300">
        Discover Crowdfunding Projects
      </Link>
    </div>
  );
};

export default CrowdfundingOpportunities;