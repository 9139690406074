import React, { useState } from 'react';
import { Settings, Users, DollarSign } from 'lucide-react';

interface WhiteLabelClient {
  id: string;
  name: string;
  domain: string;
  activeUsers: number;
  monthlyRevenue: number;
}

const WhiteLabelGamification: React.FC = () => {
  const [clients, setClients] = useState<WhiteLabelClient[]>([
    { id: '1', name: 'Fantasy Adult', domain: 'fantasyadult.com', activeUsers: 5000, monthlyRevenue: 15000 },
    { id: '2', name: 'Exotic Delights', domain: 'exoticdelights.net', activeUsers: 3000, monthlyRevenue: 9000 },
  ]);

  const [newClientName, setNewClientName] = useState('');
  const [newClientDomain, setNewClientDomain] = useState('');

  const addNewClient = () => {
    if (newClientName && newClientDomain) {
      const newClient: WhiteLabelClient = {
        id: (clients.length + 1).toString(),
        name: newClientName,
        domain: newClientDomain,
        activeUsers: 0,
        monthlyRevenue: 0,
      };
      setClients([...clients, newClient]);
      setNewClientName('');
      setNewClientDomain('');
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md mb-8">
      <h2 className="text-2xl font-semibold mb-4 flex items-center">
        <Settings className="mr-2 text-purple-600" /> White-Label Gamification
      </h2>
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Add New Client</h3>
        <div className="flex space-x-2">
          <input
            type="text"
            value={newClientName}
            onChange={(e) => setNewClientName(e.target.value)}
            placeholder="Client Name"
            className="flex-grow p-2 border rounded"
          />
          <input
            type="text"
            value={newClientDomain}
            onChange={(e) => setNewClientDomain(e.target.value)}
            placeholder="Domain"
            className="flex-grow p-2 border rounded"
          />
          <button
            onClick={addNewClient}
            className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 transition duration-300"
          >
            Add Client
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 text-left">Client Name</th>
              <th className="py-2 px-4 text-left">Domain</th>
              <th className="py-2 px-4 text-right">Active Users</th>
              <th className="py-2 px-4 text-right">Monthly Revenue</th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client) => (
              <tr key={client.id} className="border-b">
                <td className="py-2 px-4">{client.name}</td>
                <td className="py-2 px-4">{client.domain}</td>
                <td className="py-2 px-4 text-right flex items-center justify-end">
                  <Users size={16} className="mr-1 text-blue-500" />
                  {client.activeUsers.toLocaleString()}
                </td>
                <td className="py-2 px-4 text-right flex items-center justify-end">
                  <DollarSign size={16} className="mr-1 text-green-500" />
                  {client.monthlyRevenue.toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WhiteLabelGamification;