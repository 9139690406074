import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface Investment {
  id: string;
  name: string;
  amount: number;
  currentValue: number;
  history: { date: string; value: number }[];
}

const PortfolioTracker: React.FC = () => {
  const [investments, setInvestments] = useState<Investment[]>([]);

  useEffect(() => {
    // Simulated API call to fetch user's investments
    const fetchInvestments = async () => {
      // In a real application, this would be an API call
      const mockInvestments: Investment[] = [
        {
          id: '1',
          name: 'NFT Collection A',
          amount: 1000,
          currentValue: 1200,
          history: [
            { date: '2024-01-01', value: 1000 },
            { date: '2024-02-01', value: 1050 },
            { date: '2024-03-01', value: 1100 },
            { date: '2024-04-01', value: 1200 },
          ],
        },
        {
          id: '2',
          name: 'Laundromat Chain',
          amount: 5000,
          currentValue: 5500,
          history: [
            { date: '2024-01-01', value: 5000 },
            { date: '2024-02-01', value: 5100 },
            { date: '2024-03-01', value: 5300 },
            { date: '2024-04-01', value: 5500 },
          ],
        },
      ];
      setInvestments(mockInvestments);
    };

    fetchInvestments();
  }, []);

  const chartData = {
    labels: investments[0]?.history.map(h => h.date) || [],
    datasets: investments.map(investment => ({
      label: investment.name,
      data: investment.history.map(h => h.value),
      borderColor: `hsl(${Math.random() * 360}, 70%, 50%)`,
      tension: 0.1,
    })),
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Your Investment Portfolio Performance',
      },
    },
  };

  return (
    <div className="bg-white p-8 rounded-lg shadow-md mb-12">
      <h2 className="text-3xl font-bold mb-6">Your Portfolio</h2>
      <div className="mb-8">
        <Line data={chartData} options={options} />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {investments.map(investment => (
          <div key={investment.id} className="border p-4 rounded-lg">
            <h3 className="text-xl font-semibold mb-2">{investment.name}</h3>
            <p>Initial Investment: ${investment.amount}</p>
            <p>Current Value: ${investment.currentValue}</p>
            <p className={investment.currentValue > investment.amount ? 'text-green-600' : 'text-red-600'}>
              {((investment.currentValue - investment.amount) / investment.amount * 100).toFixed(2)}% {investment.currentValue > investment.amount ? 'gain' : 'loss'}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PortfolioTracker;