import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface CarouselImage {
  url: string;
  alt: string;
  caption?: string;
}

const ImageCarousel: React.FC = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  // Updated images with more thematic content
  const images: CarouselImage[] = [
    {
      url: "https://images.unsplash.com/photo-1492684223066-81342ee5ff30",
      alt: "Luxury Nightclub",
      caption: "Experience Luxury Entertainment"
    },
    {
      url: "https://images.unsplash.com/photo-1563089145-599997674d42",
      alt: "Investment Growth",
      caption: "Smart Investment Opportunities"
    },
    {
      url: "https://images.unsplash.com/photo-1541746972996-4e0b0f43e02a",
      alt: "Luxury Lifestyle",
      caption: "Elite Management Services"
    },
    {
      url: "https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3",
      alt: "Stage Performance",
      caption: "Premium Entertainment"
    },
    {
      url: "https://images.unsplash.com/photo-1604147495798-57beb5d6af73",
      alt: "Luxury Fashion",
      caption: "High-End Brand Development"
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovered) {
        setCurrentImage((prevImage) => (prevImage + 1) % images.length);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isHovered, images.length]);

  const nextImage = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  const previousImage = () => {
    setCurrentImage((prevImage) => (prevImage - 1 + images.length) % images.length);
  };

  const goToImage = (index: number) => {
    setCurrentImage(index);
  };

  return (
    <div 
      className="relative w-full h-[600px] overflow-hidden rounded-lg"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Images */}
      {images.map((image, index) => (
        <div
          key={index}
          className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
            index === currentImage ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <div className="absolute inset-0 bg-gradient-to-r from-purple-900/50 to-pink-900/50 mix-blend-multiply" />
          <img
            src={image.url}
            alt={image.alt}
            className="w-full h-full object-cover"
          />
          {/* Caption */}
          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-transparent p-8">
            <h3 className="text-3xl font-bold text-white font-audiowide">{image.caption}</h3>
          </div>
        </div>
      ))}

      {/* Navigation Arrows */}
      <button
        onClick={previousImage}
        className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black/50 text-white p-3 rounded-full hover:bg-black/75 transition-all duration-200"
      >
        <ChevronLeft size={24} />
      </button>
      <button
        onClick={nextImage}
        className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black/50 text-white p-3 rounded-full hover:bg-black/75 transition-all duration-200"
      >
        <ChevronRight size={24} />
      </button>

      {/* Dots Navigation */}
      <div className="absolute bottom-24 left-0 right-0 flex justify-center space-x-3">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => goToImage(index)}
            className={`w-3 h-3 rounded-full transition-all duration-200 ${
              index === currentImage 
                ? 'bg-white scale-110' 
                : 'bg-white/50 hover:bg-white/75'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageCarousel;