import { supabase } from '../config/supabase';
import { v4 as uuidv4 } from 'uuid';

interface ModerationQueueItem {
  id: string;
  content: string;
  status: 'pending' | 'processing' | 'completed';
  is_approved: boolean;
  created_at: string;
  completed_at?: string;
}

class ContentModerationService {
  private moderationQueue: string[] = [];
  private isProcessing = false;

  async moderateContent(content: string): Promise<boolean> {
    try {
      const moderationId = uuidv4();

      const { error } = await supabase
        .from('moderation_queue')
        .insert([{
          id: moderationId,
          content,
          status: 'pending',
          is_approved: false,
          created_at: new Date().toISOString()
        }]);

      if (error) {
        console.error('Error adding content to moderation queue:', error);
        return false;
      }

      this.moderationQueue.push(moderationId);

      if (!this.isProcessing) {
        this.processQueue();
      }

      return await this.waitForModerationResult(moderationId);
    } catch (error) {
      console.error('Error in content moderation:', error);
      return false;
    }
  }

  private async waitForModerationResult(moderationId: string): Promise<boolean> {
    const maxAttempts = 10;
    const delayMs = 1000;

    for (let i = 0; i < maxAttempts; i++) {
      const { data, error } = await supabase
        .from('moderation_queue')
        .select('status, is_approved')
        .eq('id', moderationId)
        .single();

      if (error) {
        console.error('Error checking moderation status:', error);
        continue;
      }

      if (data?.status === 'completed') {
        return !!data.is_approved;
      }

      await new Promise(resolve => setTimeout(resolve, delayMs));
    }

    return false;
  }

  private async processQueue(): Promise<void> {
    if (this.isProcessing || this.moderationQueue.length === 0) {
      return;
    }

    this.isProcessing = true;

    try {
      while (this.moderationQueue.length > 0) {
        const moderationId = this.moderationQueue.shift();
        if (!moderationId) continue;

        const { data: queueItem, error } = await supabase
          .from('moderation_queue')
          .select('content')
          .eq('id', moderationId)
          .single();

        if (error || !queueItem) {
          console.error('Error fetching queue item:', error);
          continue;
        }

        const isApproved = await this.performModeration(queueItem.content);

        const { error: updateError } = await supabase
          .from('moderation_queue')
          .update({
            status: 'completed',
            is_approved: isApproved,
            completed_at: new Date().toISOString()
          })
          .eq('id', moderationId);

        if (updateError) {
          console.error('Error updating moderation result:', updateError);
        }
      }
    } catch (error) {
      console.error('Error processing moderation queue:', error);
    } finally {
      this.isProcessing = false;
    }
  }

  private async performModeration(content: string): Promise<boolean> {
    const prohibitedPatterns = [
      /\b(spam|scam|fraud)\b/i,
      /\b(hate|racist|discrimination)\b/i,
      /\b(violence|threat|kill)\b/i,
      /\b(illegal|drugs|weapons)\b/i
    ];

    return !prohibitedPatterns.some(pattern => pattern.test(content));
  }
}

export const contentModerationService = new ContentModerationService();