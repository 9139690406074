import React, { useState } from 'react';
import { Plus, MessageSquare, Heart, Share2, Filter } from 'lucide-react';
import CreatePostModal from './CreatePostModal';

interface ForumPost {
  id: string;
  title: string;
  content: string;
  author: {
    id: string;
    name: string;
    avatar: string;
  };
  category?: string;
  likes: number;
  comments: number;
  shares: number;
  timestamp: string;
}

const ForumDiscussions: React.FC = () => {
  const [showCreatePost, setShowCreatePost] = useState(false);
  const [posts, setPosts] = useState<ForumPost[]>([
    {
      id: '1',
      title: 'Getting Started in Content Creation',
      author: {
        id: '1',
        name: 'ContentPro',
        avatar: 'https://via.placeholder.com/40'
      },
      content: 'Tips and tricks for new content creators...',
      category: 'Guides',
      likes: 25,
      comments: 12,
      shares: 5,
      timestamp: new Date().toISOString()
    }
  ]);

  const handleCreatePost = (post: Partial<ForumPost>) => {
    const newPost: ForumPost = {
      id: Date.now().toString(),
      author: {
        id: 'current-user',
        name: 'Current User',
        avatar: 'https://via.placeholder.com/40'
      },
      likes: 0,
      comments: 0,
      shares: 0,
      timestamp: new Date().toISOString(),
      ...post
    } as ForumPost;
    setPosts([newPost, ...posts]);
    setShowCreatePost(false);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <button
          onClick={() => setShowCreatePost(true)}
          className="bg-purple-600 text-white px-4 py-2 rounded-full hover:bg-purple-700 transition duration-300 flex items-center"
        >
          <Plus size={20} className="mr-2" />
          Create Post
        </button>
        <button className="flex items-center text-gray-600 hover:text-gray-800">
          <Filter size={20} className="mr-2" />
          Filter
        </button>
      </div>

      <div className="space-y-6">
        {posts.map(post => (
          <div key={post.id} className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex items-center mb-4">
              <img
                src={post.author.avatar}
                alt={post.author.name}
                className="w-10 h-10 rounded-full mr-3"
              />
              <div>
                <h3 className="font-semibold">{post.author.name}</h3>
                <p className="text-sm text-gray-500">
                  {new Date(post.timestamp).toLocaleDateString()}
                </p>
              </div>
            </div>
            <h2 className="text-xl font-bold mb-2">{post.title}</h2>
            <p className="text-gray-700 mb-4">{post.content}</p>
            {post.category && (
              <span className="inline-block bg-purple-100 text-purple-800 text-sm px-3 py-1 rounded-full mb-4">
                {post.category}
              </span>
            )}
            <div className="flex items-center space-x-6 text-gray-500">
              <button className="flex items-center hover:text-red-500">
                <Heart size={20} className="mr-1" />
                {post.likes}
              </button>
              <button className="flex items-center hover:text-blue-500">
                <MessageSquare size={20} className="mr-1" />
                {post.comments}
              </button>
              <button className="flex items-center hover:text-green-500">
                <Share2 size={20} className="mr-1" />
                {post.shares}
              </button>
            </div>
          </div>
        ))}
      </div>

      {showCreatePost && (
        <CreatePostModal
          onClose={() => setShowCreatePost(false)}
          onSubmit={handleCreatePost}
        />
      )}
    </div>
  );
};

export default ForumDiscussions;