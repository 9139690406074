import React from 'react';

const TermsOfServicePage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
      <p className="mb-4">Effective Date: [Insert Date]</p>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
        <p>By accessing or using Studio Mystique, you agree to be bound by these Terms. If you do not agree to these Terms, you must cease use of our services.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">2. User Eligibility</h2>
        <p>You must be at least 18 years old to use Studio Mystique's services. By accessing the site, you affirm that you are of legal age in your jurisdiction to view adult content and make investments.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">3. Content Rights and Usage</h2>
        <p>By uploading content to Studio Mystique, you grant Studio Mystique and its affiliates a worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform the content in connection with the Service and Studio Mystique's (and its successors' and affiliates') business, including without limitation for promoting and redistributing part or all of the Service (and derivative works thereof) in any media formats and through any media channels.</p>
        <p className="mt-4">This license includes the right for Studio Mystique to use any uploaded content for marketing purposes, including but not limited to promotional materials, advertisements, and social media content. While Studio Mystique will make reasonable efforts to use content in a manner consistent with the platform's and content creator's brand, the final decision on content usage remains with Studio Mystique.</p>
        <p className="mt-4">You represent and warrant that you own or have the necessary licenses, rights, consents, and permissions to grant the rights and licenses to Studio Mystique under these Terms of Service.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">4. Investment Disclaimer</h2>
        <p>Studio Mystique facilitates investments in various opportunities such as NFTs, crypto, and private equity projects. However, we do not guarantee returns or the success of any investments. Users are responsible for conducting their own due diligence. Studio Mystique will not be liable for any financial losses.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">5. Limitation of Liability</h2>
        <p>Studio Mystique shall not be held liable for any direct, indirect, incidental, or consequential damages arising from the use of the website, services, or any content. This includes financial losses, legal issues, or reputational damage resulting from the use or misuse of the platform.</p>
      </section>

      <p className="mt-8 text-sm text-gray-600">For the full terms of service, please contact us at legal@studiomystique.com</p>
    </div>
  );
};

export default TermsOfServicePage;