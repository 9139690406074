import React, { useState } from 'react';
import { Upload, Search, Filter, Grid, List } from 'lucide-react';
import VideoGrid from './tube/VideoGrid';
import VideoList from './tube/VideoList';
import CSVImport from './tube/CSVImport';
import VideoFilters from './tube/VideoFilters';
import VideoSearch from './tube/VideoSearch';
import { contentModerationService } from '../services/ContentModerationService';

interface Video {
  id: string;
  title: string;
  description: string;
  thumbnail: string;
  duration: string;
  views: number;
  platform: string;
  embedUrl: string;
  categories: string[];
  tags: string[];
  uploadDate: string;
}

const TubeContent: React.FC = () => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [showImport, setShowImport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    platform: '',
    category: '',
    duration: '',
    date: ''
  });

  const handleCSVImport = async (csvData: any[]) => {
    setLoading(true);
    try {
      // Process and validate each video
      const processedVideos = await Promise.all(
        csvData.map(async (video) => {
          // Moderate content before importing
          const moderationResult = await contentModerationService.moderateText(
            `${video.title} ${video.description}`
          );

          if (!moderationResult.isApproved) {
            console.warn(`Video "${video.title}" failed moderation:`, moderationResult.reason);
            return null;
          }

          return {
            id: video.id || Math.random().toString(36).substr(2, 9),
            title: video.title,
            description: video.description,
            thumbnail: video.thumbnail_url,
            duration: video.duration,
            views: parseInt(video.views) || 0,
            platform: video.platform,
            embedUrl: video.embed_url,
            categories: video.categories?.split(',') || [],
            tags: video.tags?.split(',') || [],
            uploadDate: video.upload_date || new Date().toISOString()
          };
        })
      );

      // Filter out failed moderation
      const validVideos = processedVideos.filter(Boolean) as Video[];
      setVideos(prev => [...prev, ...validVideos]);
    } catch (error) {
      console.error('Error importing videos:', error);
    } finally {
      setLoading(false);
      setShowImport(false);
    }
  };

  const handleSearch = (query: string) => {
    // Implement search functionality
  };

  const handleFilterChange = (newFilters: typeof filters) => {
    setFilters(newFilters);
  };

  return (
    <div className="space-y-6">
      {/* Header Controls */}
      <div className="flex flex-wrap gap-4 items-center justify-between">
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setShowImport(true)}
            className="bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition flex items-center"
          >
            <Upload className="mr-2" size={20} />
            Import Content
          </button>
          <VideoSearch onSearch={handleSearch} />
        </div>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setViewMode('grid')}
            className={`p-2 rounded-lg ${viewMode === 'grid' ? 'bg-purple-100' : 'hover:bg-gray-100'}`}
          >
            <Grid size={20} />
          </button>
          <button
            onClick={() => setViewMode('list')}
            className={`p-2 rounded-lg ${viewMode === 'list' ? 'bg-purple-100' : 'hover:bg-gray-100'}`}
          >
            <List size={20} />
          </button>
        </div>
      </div>

      {/* Filters */}
      <VideoFilters filters={filters} onChange={handleFilterChange} />

      {/* Content Display */}
      {viewMode === 'grid' ? (
        <VideoGrid videos={videos} />
      ) : (
        <VideoList videos={videos} />
      )}

      {/* CSV Import Modal */}
      {showImport && (
        <CSVImport
          onClose={() => setShowImport(false)}
          onImport={handleCSVImport}
          loading={loading}
        />
      )}
    </div>
  );
};

export default TubeContent;