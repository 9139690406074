import React from 'react';
import { Camera, DollarSign, TrendingUp, Star } from 'lucide-react';

const IntroSection: React.FC = () => {
  return (
    <div className="py-16 bg-gradient-to-b from-purple-900/5 to-pink-900/5">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4 gradient-text">Welcome to Studio Mystique</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Where luxury meets opportunity in the adult entertainment industry
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300">
            <Camera className="w-12 h-12 text-purple-600 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Content Creation</h3>
            <p className="text-gray-600">
              Professional content creation and management services for adult entertainers
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300">
            <DollarSign className="w-12 h-12 text-green-600 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Investment</h3>
            <p className="text-gray-600">
              Diverse investment opportunities in adult entertainment and related industries
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300">
            <TrendingUp className="w-12 h-12 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Growth</h3>
            <p className="text-gray-600">
              Strategic business development and career advancement solutions
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300">
            <Star className="w-12 h-12 text-yellow-600 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Premium</h3>
            <p className="text-gray-600">
              Exclusive content and VIP experiences for discerning clients
            </p>
          </div>
        </div>

        <div className="mt-16 text-center">
          <h3 className="text-2xl font-bold mb-4">Ready to Get Started?</h3>
          <p className="text-gray-600 mb-8 max-w-2xl mx-auto">
            Join Studio Mystique today and discover how we can help you achieve your goals in the adult entertainment industry.
          </p>
          <button className="bg-purple-600 text-white px-8 py-3 rounded-full hover:bg-purple-700 transition duration-300">
            Join Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default IntroSection;