import React from 'react';
import { Link } from 'react-router-dom';
import { Home, TrendingUp } from 'lucide-react';

const RealEstateInvesting: React.FC = () => {
  return (
    <div className="bg-white p-8 rounded-lg shadow-md mb-12">
      <h2 className="text-3xl font-bold mb-4 text-purple-800">Real Estate Investing</h2>
      <p className="text-lg mb-6">
        Diversify your portfolio with our curated selection of real estate investment opportunities. From residential properties to commercial spaces, we offer a range of options to suit your investment goals.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-6">
        <div className="flex items-start">
          <Home className="mr-4 text-purple-600" size={24} />
          <div>
            <h3 className="text-xl font-semibold mb-2">Residential Properties</h3>
            <p>Invest in single-family homes, multi-unit buildings, and apartment complexes in high-growth areas.</p>
          </div>
        </div>
        <div className="flex items-start">
          <TrendingUp className="mr-4 text-purple-600" size={24} />
          <div>
            <h3 className="text-xl font-semibold mb-2">Commercial Real Estate</h3>
            <p>Explore opportunities in office buildings, retail spaces, and industrial properties with strong potential for appreciation.</p>
          </div>
        </div>
      </div>
      <Link to="/real-estate-investments" className="bg-purple-600 text-white px-6 py-3 rounded-full hover:bg-purple-700 transition duration-300">
        Explore Real Estate Opportunities
      </Link>
    </div>
  );
};

export default RealEstateInvesting;