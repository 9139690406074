import React from 'react';

interface InvestorTestimonialProps {
  name: string;
  testimonial: string;
  investment: string;
}

const InvestorTestimonial: React.FC<InvestorTestimonialProps> = ({ name, testimonial, investment }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <p className="text-lg italic mb-4">"{testimonial}"</p>
      <div className="flex justify-between items-center">
        <span className="font-semibold">{name}</span>
        <span className="text-sm text-gray-600">{investment}</span>
      </div>
    </div>
  );
};

export default InvestorTestimonial;