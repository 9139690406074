import React, { useState, useRef } from 'react';
import { Music, Volume2, VolumeX, SkipBack, SkipForward, Play, Pause } from 'lucide-react';

interface Track {
  id: string;
  title: string;
  url: string;
}

const MusicPlayer: React.FC = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [currentTrack, setCurrentTrack] = useState(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const playlist: Track[] = [
    { id: '1', title: 'Lofi Beats', url: '/music/lofi-beats.mp3' },
    { id: '2', title: 'Chill Vibes', url: '/music/chill-vibes.mp3' },
    // Add more tracks
  ];

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleMute = () => {
    if (audioRef.current) {
      audioRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const nextTrack = () => {
    setCurrentTrack((prev) => (prev + 1) % playlist.length);
  };

  const prevTrack = () => {
    setCurrentTrack((prev) => (prev - 1 + playlist.length) % playlist.length);
  };

  return (
    <div className="fixed bottom-4 right-4 bg-white/90 backdrop-blur-sm p-4 rounded-lg shadow-lg z-50 animate-slideUp">
      <audio
        ref={audioRef}
        src={playlist[currentTrack].url}
        onEnded={nextTrack}
        className="hidden"
      />
      <div className="flex items-center space-x-4">
        <Music className="text-purple-600" />
        <span className="text-sm font-medium">{playlist[currentTrack].title}</span>
        <div className="flex items-center space-x-2">
          <button
            onClick={prevTrack}
            className="p-2 hover:bg-purple-100 rounded-full transition-colors"
          >
            <SkipBack size={16} />
          </button>
          <button
            onClick={togglePlay}
            className="p-2 bg-purple-600 text-white rounded-full hover:bg-purple-700 transition-colors"
          >
            {isPlaying ? <Pause size={16} /> : <Play size={16} />}
          </button>
          <button
            onClick={nextTrack}
            className="p-2 hover:bg-purple-100 rounded-full transition-colors"
          >
            <SkipForward size={16} />
          </button>
          <button
            onClick={toggleMute}
            className="p-2 hover:bg-purple-100 rounded-full transition-colors"
          >
            {isMuted ? <VolumeX size={16} /> : <Volume2 size={16} />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MusicPlayer;