import React, { useState, useEffect } from 'react';
import { BarChart2, Users, DollarSign, AlertTriangle } from 'lucide-react';

interface FranchiseData {
  id: string;
  name: string;
  revenue: number;
  users: number;
  contentUploads: number;
  fraudAlerts: number;
}

const FranchiseDashboard: React.FC = () => {
  const [franchiseData, setFranchiseData] = useState<FranchiseData[]>([]);

  useEffect(() => {
    // In a real application, this would fetch data from an API
    const mockData: FranchiseData[] = [
      { id: '1', name: 'Franchise A', revenue: 50000, users: 1000, contentUploads: 150, fraudAlerts: 2 },
      { id: '2', name: 'White Label B', revenue: 75000, users: 1500, contentUploads: 200, fraudAlerts: 0 },
      { id: '3', name: 'Franchise C', revenue: 30000, users: 800, contentUploads: 100, fraudAlerts: 1 },
    ];
    setFranchiseData(mockData);
  }, []);

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Franchise/White Label Dashboard</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {franchiseData.map(franchise => (
          <div key={franchise.id} className="border p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-2">{franchise.name}</h3>
            <div className="space-y-2">
              <div className="flex items-center">
                <DollarSign size={16} className="text-green-500 mr-2" />
                <span>Revenue: ${franchise.revenue.toLocaleString()}</span>
              </div>
              <div className="flex items-center">
                <Users size={16} className="text-blue-500 mr-2" />
                <span>Users: {franchise.users.toLocaleString()}</span>
              </div>
              <div className="flex items-center">
                <BarChart2 size={16} className="text-purple-500 mr-2" />
                <span>Content Uploads: {franchise.contentUploads}</span>
              </div>
              <div className="flex items-center">
                <AlertTriangle size={16} className="text-red-500 mr-2" />
                <span>Fraud Alerts: {franchise.fraudAlerts}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FranchiseDashboard;