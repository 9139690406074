import React from 'react';
import { Link } from 'react-router-dom';

const NFTDigitalAssets: React.FC = () => {
  return (
    <div className="bg-white p-8 rounded-lg shadow-md mb-12">
      <h2 className="text-3xl font-bold mb-4 text-purple-800">Invest in the Future of Digital Assets</h2>
      <p className="text-lg mb-4">
        Explore our exclusive NFT collections and cryptocurrency investments. Be part of the digital revolution in the adult entertainment industry.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h3 className="text-xl font-semibold mb-2">NFT Collections</h3>
          <p className="mb-4">Own unique digital art and content from top adult creators. Limited editions with potential for high returns.</p>
          <Link to="/nft-details" className="text-purple-600 hover:text-purple-800">Learn More About NFTs</Link>
        </div>
        <div>
          <h3 className="text-xl font-semibold mb-2">Cryptocurrency</h3>
          <p className="mb-4">Invest in Studio Mystique's native token and other crypto assets powering the future of adult entertainment.</p>
          <Link to="/crypto-details" className="text-purple-600 hover:text-purple-800">Invest in Crypto</Link>
        </div>
      </div>
    </div>
  );
};

export default NFTDigitalAssets;