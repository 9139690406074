import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Video, 
  Coins, 
  ChevronDown,
  Users,
  Camera,
  DollarSign,
  Briefcase,
  Heart,
  Settings,
  MessageSquare,
  Globe,
  ShoppingBag,
  Award,
  Menu,
  X,
  Mail,
  Sparkles
} from 'lucide-react';

const Header: React.FC = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [showInvestments, setShowInvestments] = useState(false);
  const [showCommunity, setShowCommunity] = useState(false);

  const services = [
    { name: 'Social Media Management (Beta)', path: '/services/social-media', icon: <Globe size={16} /> },
    { name: 'OnlyFans Management', path: '/services/onlyfans', icon: <Heart size={16} /> },
    { name: 'Webcam Management (Beta)', path: '/services/webcam', icon: <Video size={16} /> },
    { name: 'Talent Management', path: '/services/talent', icon: <Users size={16} /> },
    { name: 'SMMA Services (Coming Soon)', path: '/services/smma', icon: <Briefcase size={16} /> },
    { name: 'Model Management', path: '/services/model', icon: <Camera size={16} /> },
  ];

  const investments = [
    { name: 'Model Tokens (Beta)', path: '/model-tokens', icon: <Coins size={16} /> },
    { name: 'NFT Marketplace (Coming Soon)', path: '/nft-marketplace', icon: <Award size={16} /> },
    { name: 'Real Estate', path: '/investments/real-estate', icon: <DollarSign size={16} /> },
    { name: 'Laundromats', path: '/investments/laundromats', icon: <Briefcase size={16} /> },
    { name: 'Car Washes', path: '/investments/car-washes', icon: <Briefcase size={16} /> },
    { name: 'Investment Portfolio (Beta)', path: '/investments/portfolio', icon: <DollarSign size={16} /> },
  ];

  const community = [
    { name: 'Social Platform (Beta)', path: '/social', icon: <Users size={16} /> },
    { name: 'Forums', path: '/forum', icon: <MessageSquare size={16} /> },
    { name: 'Live Events (Coming Soon)', path: '/events', icon: <Video size={16} /> },
    { name: 'Gamification (Beta)', path: '/gamification', icon: <Award size={16} /> },
    { name: 'Leaderboard', path: '/leaderboard', icon: <Award size={16} /> },
    { name: 'Rewards', path: '/rewards', icon: <Award size={16} /> },
  ];

  return (
    <header className="bg-white shadow-md sticky top-0 z-50">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <Link to="/" className="text-2xl font-bold gradient-text font-audiowide flex items-center">
            Studio Mystique
            <span className="ml-2 text-xs bg-gradient-to-r from-purple-600 to-pink-600 text-white px-2 py-1 rounded-full flex items-center">
              <Sparkles size={12} className="mr-1" />
              Beta
            </span>
          </Link>

          {/* Mobile Menu Button */}
          <button 
            className="md:hidden"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
            {showMobileMenu ? <X /> : <Menu />}
          </button>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center space-x-6">
            <div className="relative group">
              <button 
                className="flex items-center text-gray-700 hover:text-purple-600"
                onMouseEnter={() => setShowServices(true)}
                onMouseLeave={() => setShowServices(false)}
              >
                <Settings size={16} className="mr-1" />
                Services
                <ChevronDown size={16} className="ml-1" />
              </button>
              {showServices && (
                <div 
                  className="absolute top-full left-0 w-64 mt-2 bg-white rounded-lg shadow-lg py-2"
                  onMouseEnter={() => setShowServices(true)}
                  onMouseLeave={() => setShowServices(false)}
                >
                  {services.map((service) => (
                    <Link
                      key={service.path}
                      to={service.path}
                      className="flex items-center px-4 py-2 text-gray-700 hover:bg-purple-50"
                    >
                      {service.icon}
                      <span className="ml-2">{service.name}</span>
                    </Link>
                  ))}
                </div>
              )}
            </div>

            <div className="relative group">
              <button 
                className="flex items-center text-gray-700 hover:text-purple-600"
                onMouseEnter={() => setShowInvestments(true)}
                onMouseLeave={() => setShowInvestments(false)}
              >
                <DollarSign size={16} className="mr-1" />
                Investments
                <ChevronDown size={16} className="ml-1" />
              </button>
              {showInvestments && (
                <div 
                  className="absolute top-full left-0 w-64 mt-2 bg-white rounded-lg shadow-lg py-2"
                  onMouseEnter={() => setShowInvestments(true)}
                  onMouseLeave={() => setShowInvestments(false)}
                >
                  {investments.map((investment) => (
                    <Link
                      key={investment.path}
                      to={investment.path}
                      className="flex items-center px-4 py-2 text-gray-700 hover:bg-purple-50"
                    >
                      {investment.icon}
                      <span className="ml-2">{investment.name}</span>
                    </Link>
                  ))}
                </div>
              )}
            </div>

            <div className="relative group">
              <button 
                className="flex items-center text-gray-700 hover:text-purple-600"
                onMouseEnter={() => setShowCommunity(true)}
                onMouseLeave={() => setShowCommunity(false)}
              >
                <Users size={16} className="mr-1" />
                Community
                <ChevronDown size={16} className="ml-1" />
              </button>
              {showCommunity && (
                <div 
                  className="absolute top-full left-0 w-64 mt-2 bg-white rounded-lg shadow-lg py-2"
                  onMouseEnter={() => setShowCommunity(true)}
                  onMouseLeave={() => setShowCommunity(false)}
                >
                  {community.map((item) => (
                    <Link
                      key={item.path}
                      to={item.path}
                      className="flex items-center px-4 py-2 text-gray-700 hover:bg-purple-50"
                    >
                      {item.icon}
                      <span className="ml-2">{item.name}</span>
                    </Link>
                  ))}
                </div>
              )}
            </div>

            <Link to="/shop" className="flex items-center text-gray-700 hover:text-purple-600">
              <ShoppingBag size={16} className="mr-1" />
              Shop
              <span className="ml-1 text-xs bg-purple-100 text-purple-600 px-1.5 rounded-full">Beta</span>
            </Link>

            <Link to="/newsletter" className="flex items-center text-gray-700 hover:text-purple-600">
              <Mail size={16} className="mr-1" />
              Newsletter
            </Link>

            <a
              href="https://studiomystique.chaturbate.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-gray-700 hover:text-purple-600"
            >
              <Video size={16} className="mr-1" />
              Live Cams
            </a>
          </nav>

          {/* User Actions */}
          <div className="hidden md:flex items-center space-x-4">
            <Link to="/dashboard" className="text-gray-700 hover:text-purple-600">
              <Settings size={20} />
            </Link>
            <Link to="/messages" className="text-gray-700 hover:text-purple-600">
              <MessageSquare size={20} />
            </Link>
            <Link to="/profile" className="text-gray-700 hover:text-purple-600">
              <Users size={20} />
            </Link>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {showMobileMenu && (
        <div className="md:hidden bg-white border-t">
          <div className="px-4 py-2 space-y-2">
            {services.map((service) => (
              <Link
                key={service.path}
                to={service.path}
                className="block px-4 py-2 text-gray-700 hover:bg-purple-50"
                onClick={() => setShowMobileMenu(false)}
              >
                <div className="flex items-center">
                  {service.icon}
                  <span className="ml-2">{service.name}</span>
                </div>
              </Link>
            ))}
            {investments.map((investment) => (
              <Link
                key={investment.path}
                to={investment.path}
                className="block px-4 py-2 text-gray-700 hover:bg-purple-50"
                onClick={() => setShowMobileMenu(false)}
              >
                <div className="flex items-center">
                  {investment.icon}
                  <span className="ml-2">{investment.name}</span>
                </div>
              </Link>
            ))}
            {community.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className="block px-4 py-2 text-gray-700 hover:bg-purple-50"
                onClick={() => setShowMobileMenu(false)}
              >
                <div className="flex items-center">
                  {item.icon}
                  <span className="ml-2">{item.name}</span>
                </div>
              </Link>
            ))}
            <Link
              to="/newsletter"
              className="block px-4 py-2 text-gray-700 hover:bg-purple-50"
              onClick={() => setShowMobileMenu(false)}
            >
              <div className="flex items-center">
                <Mail size={16} className="mr-2" />
                <span>Newsletter</span>
              </div>
            </Link>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;