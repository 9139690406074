import React, { useState, useEffect } from 'react';
import { CreditCard, AlertCircle } from 'lucide-react';

interface Subscription {
  id: string;
  name: string;
  status: 'active' | 'expired' | 'suspended';
  expirationDate: string;
  monthlyFee: number;
}

const SubscriptionManager: React.FC = () => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  useEffect(() => {
    // In a real application, this would fetch data from an API
    const mockSubscriptions: Subscription[] = [
      {
        id: '1',
        name: 'Franchise A',
        status: 'active',
        expirationDate: '2024-12-31',
        monthlyFee: 499.99,
      },
      {
        id: '2',
        name: 'White Label B',
        status: 'expired',
        expirationDate: '2024-03-15',
        monthlyFee: 299.99,
      },
      {
        id: '3',
        name: 'Franchise C',
        status: 'suspended',
        expirationDate: '2024-06-30',
        monthlyFee: 399.99,
      },
    ];
    setSubscriptions(mockSubscriptions);
  }, []);

  const handleRenewSubscription = (id: string) => {
    // In a real application, this would call an API to renew the subscription
    setSubscriptions(subscriptions.map(sub =>
      sub.id === id ? { ...sub, status: 'active', expirationDate: '2025-03-31' } : sub
    ));
  };

  const handleSuspendSubscription = (id: string) => {
    // In a real application, this would call an API to suspend the subscription
    setSubscriptions(subscriptions.map(sub =>
      sub.id === id ? { ...sub, status: 'suspended' } : sub
    ));
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Subscription Management</h2>
      <div className="space-y-4">
        {subscriptions.map(sub => (
          <div key={sub.id} className="border p-4 rounded-lg">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-lg font-semibold">{sub.name}</h3>
              <span className={`px-2 py-1 rounded-full text-sm ${
                sub.status === 'active' ? 'bg-green-200 text-green-800' :
                sub.status === 'expired' ? 'bg-red-200 text-red-800' :
                'bg-yellow-200 text-yellow-800'
              }`}>
                {sub.status.charAt(0).toUpperCase() + sub.status.slice(1)}
              </span>
            </div>
            <p className="text-sm text-gray-600 mb-2">Expiration: {sub.expirationDate}</p>
            <p className="text-sm text-gray-600 mb-4">Monthly Fee: ${sub.monthlyFee.toFixed(2)}</p>
            <div className="flex space-x-2">
              {sub.status !== 'active' && (
                <button
                  onClick={() => handleRenewSubscription(sub.id)}
                  className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition duration-300 flex items-center"
                >
                  <CreditCard size={16} className="mr-2" />
                  Renew Subscription
                </button>
              )}
              {sub.status === 'active' && (
                <button
                  onClick={() => handleSuspendSubscription(sub.id)}
                  className="bg-yellow-500 text-white px-4 py-2 rounded-full hover:bg-yellow-600 transition duration-300 flex items-center"
                >
                  <AlertCircle size={16} className="mr-2" />
                  Suspend Subscription
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionManager;