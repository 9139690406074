import React from 'react';
import { Link } from 'react-router-dom';

interface ServiceBoxProps {
  title: string;
  path: string;
  icon: React.ReactNode;
  description: string;
  features: string[];
}

const ServiceBox: React.FC<ServiceBoxProps> = ({ title, path, icon, description, features }) => {
  return (
    <Link to={path} className="service-card p-6 rounded-lg">
      <div className="flex items-center mb-4">
        {icon}
        <h3 className="text-xl font-semibold">{title}</h3>
      </div>
      <p className="text-gray-600 mb-4">{description}</p>
      <ul className="space-y-2">
        {features.map((feature, fIndex) => (
          <li key={fIndex} className="flex items-center text-sm text-gray-600">
            <span className="w-1.5 h-1.5 bg-purple-500 rounded-full mr-2"></span>
            {feature}
          </li>
        ))}
      </ul>
    </Link>
  );
};

export default ServiceBox;