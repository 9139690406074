import React, { useState, useEffect } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { ShoppingBag, Tag, Search } from 'lucide-react';
import ProductCard from '../components/ProductCard';
import Pagination from '../components/Pagination';

interface Product {
  id: string;
  name: string;
  price: number;
  image: string;
  description: string;
}

interface AffiliateProduct extends Product {
  affiliateLink: string;
}

interface ShopifyStore {
  id: string;
  name: string;
  products: Product[];
}

const shopifyStores: ShopifyStore[] = [
  {
    id: 'store1',
    name: 'Studio Mystique Official',
    products: [
      {
        id: 'p1',
        name: 'Exclusive T-Shirt',
        price: 29.99,
        image: 'https://via.placeholder.com/200x200?text=T-Shirt',
        description: 'Limited edition Studio Mystique t-shirt'
      },
      // Add more products...
    ]
  },
  // Add more stores...
];

const affiliateProducts: AffiliateProduct[] = [
  {
    id: 'a1',
    name: 'Premium Webcam',
    price: 199.99,
    image: 'https://via.placeholder.com/200x200?text=Webcam',
    description: 'High-quality webcam for content creators',
    affiliateLink: 'https://example.com/affiliate/webcam'
  },
  // Add more affiliate products...
];

const ShopPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState('shopify');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 9;

  const filteredProducts = activeTab === 'shopify'
    ? shopifyStores.flatMap(store => store.products).filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : affiliateProducts.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Shop</h1>
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        <TabsList>
          <TabsTrigger value="shopify">
            <ShoppingBag className="mr-2" />
            Shopify Stores
          </TabsTrigger>
          <TabsTrigger value="affiliate">
            <Tag className="mr-2" />
            Affiliate Products
          </TabsTrigger>
        </TabsList>
        <div className="my-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search products..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-2 pl-10 border rounded"
            />
            <Search className="absolute left-3 top-2.5 text-gray-400" />
          </div>
        </div>
        <TabsContent value="shopify">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {currentProducts.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </div>
        </TabsContent>
        <TabsContent value="affiliate">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {currentProducts.map((product) => (
              <ProductCard key={product.id} product={product as AffiliateProduct} />
            ))}
          </div>
        </TabsContent>
      </Tabs>
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(filteredProducts.length / productsPerPage)}
        onPageChange={paginate}
      />
    </div>
  );
};

export default ShopPage;