import React from 'react';
import UserReview from '../components/UserReview';
import Advertisement from '../components/Advertisements';

const AboutPage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold mb-8 text-center text-purple-800">About Studio Mystique</h1>
      
      {/* Top Banner Ad */}
      <Advertisement type="banner" />
      
      <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-md mb-12">
        <p className="text-lg mb-6">
          At Studio Mystique, we specialize in empowering elite adult brands, content creators, and influencers with comprehensive management services and advanced digital marketing solutions tailored to their unique goals. Our mission is to help creators elevate their brand, increase online visibility, and maximize revenue streams using cutting-edge tools and proven strategies that drive success in the highly competitive adult entertainment industry.
        </p>
        <p className="text-lg mb-6">
          We offer a full-service approach designed to fuel both short-term growth and sustainable long-term success. From innovative SaaS tools that simplify content management and fan engagement to expert SEO strategies, social media marketing, and influencer collaborations, we ensure your brand stands out in the crowded adult niche.
        </p>
        <p className="text-lg mb-6">
          At Studio Mystique, we believe in personalized strategies that highlight each brand's unique selling proposition (USP). Whether you're a porn content creator, adult influencer, or adult business, our custom marketing plans are built to drive organic traffic, expand your audience, and increase brand awareness across multiple platforms.
        </p>
      </div>

      <div className="bg-white p-8 rounded-lg shadow-md mb-12">
        <h2 className="text-3xl font-bold mb-4">Our Expertise</h2>
        <p className="mb-6">
          With our focus on search engine optimization (SEO), advanced analytics tracking, and revenue optimization techniques, we help you reach your target audience and generate higher engagement rates. We specialize in adult SEO, social media growth, brand development, and content marketing to dominate your niche.
        </p>
        <p className="mb-6">
          Backed by data-driven strategies, we provide real-time insights that allow you to make informed decisions, ensuring your brand thrives in the ever-evolving digital space. Whether you're building a personal brand or growing a large-scale adult business, Studio Mystique is here to provide the tools, strategies, and support needed to achieve your goals.
        </p>
      </div>

      <div className="bg-white p-8 rounded-lg shadow-md mb-12">
        <h2 className="text-3xl font-bold mb-4">Sustainable Growth Beyond Adult Entertainment</h2>
        <p className="mb-4">
          At Studio Mystique, we believe in empowering adult content creators to build a future that goes beyond the camera. Our Adult to Entrepreneur Pathway is designed to guide performers in expanding their income through strategic investments, including cryptocurrency, NFTs, business franchises, and real estate investments. We offer expert advice to ensure you capitalize on opportunities that create financial security and wealth.
        </p>
      </div>

      <div className="bg-white p-8 rounded-lg shadow-md mb-12">
        <h2 className="text-3xl font-bold mb-4">From Adult Content to Entrepreneurial Success</h2>
        <p className="mb-4">
          Your brand is your most valuable asset. Studio Mystique offers adult performers a unique opportunity to transition into successful business ventures, ensuring that your adult entertainment career lays the foundation for entrepreneurial growth. Whether you're looking to manage your own adult brand or invest in other sectors, Studio Mystique ensures you have the tools and support needed for lifelong success.
        </p>
        <p className="mb-4">
          With our SEO-driven marketing strategies, your brand continues to grow online, maximizing visibility on adult content platforms, such as Pornhub, Xvideos, and OnlyFans. This allows performers to increase their following and revenue while building a reputation that lasts.
        </p>
      </div>

      {/* Native Ad */}
      <Advertisement type="native" />

      <div className="bg-white p-8 rounded-lg shadow-md mb-12">
        <h2 className="text-3xl font-bold mb-4">Entry and Exit Program with Strategic Support</h2>
        <p className="mb-4">
          Studio Mystique is your partner at every stage of your career, from adult content creation to investment management. Our services include brand development, digital marketing, SEO strategies, and legal compliance, ensuring that your career both in and out of the adult entertainment industry thrives. Whether you are just starting out or planning your exit, Studio Mystique is committed to your long-term success.
        </p>
        <div className="mt-8 text-center">
          <p className="text-xl font-bold text-purple-800">Join Studio Mystique today and discover how our expert adult marketing services can help you become a leader in the adult industry.</p>
        </div>
      </div>

      <div className="mb-12">
        <h2 className="text-3xl font-bold mb-8 text-center">What Our Clients Say</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <UserReview
            name="Riley T."
            role="Influencer"
            review="I love how easy it is to manage everything in one place—from marketing to finances—Studio Mystique is a must for anyone serious about their career!"
          />
          <UserReview
            name="Camila P."
            role="Business Owner"
            review="The SEO and marketing tools provided have doubled my traffic in just a few months. Studio Mystique knows how to get results."
          />
          <UserReview
            name="Xavier L."
            role="Performer"
            review="Studio Mystique takes the hassle out of managing my business. Their expertise in adult branding is unparalleled."
          />
          <UserReview
            name="Lily S."
            role="OnlyFans Model"
            review="Since I started using Studio Mystique's advanced marketing solutions, my subscriber count has exploded. I can't recommend them enough!"
          />
        </div>
      </div>

      {/* Float Ad */}
      <Advertisement type="float" />
    </div>
  );
};

export default AboutPage;