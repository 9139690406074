import React, { useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { Video, Camera, Users, Star } from 'lucide-react';
import ContentEditor from '../components/ContentEditor';
import IndustryResources from '../components/IndustryResources';
import PremiumContentSection from '../components/PremiumContentSection';
import CreatorDashboard from '../components/CreatorDashboard';
import LiveStreamingSection from '../components/LiveStreamingSection';
import NFTMarketplace from '../components/NFTMarketplace';
import FanInteractionSection from '../components/FanInteractionSection';
import ChaturbateSection from '../components/ChaturbateSection';
import Advertisement from '../components/Advertisements';
import TubeContent from '../components/TubeContent';

const AdultContentPage: React.FC = () => {
  const [content, setContent] = useState<any>(null);
  const [isCreator, setIsCreator] = useState(false);
  const [activeTab, setActiveTab] = useState('tube');

  const handleContentChange = (data: any) => {
    setContent(data);
    console.log('Content updated:', data);
  };

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold mb-8 text-center text-purple-800">Adult Content Hub</h1>
      
      {/* Top Banner Ad */}
      <Advertisement type="banner" />
      
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        <TabsList className="mb-8">
          <TabsTrigger value="tube" className="flex items-center">
            <Video className="mr-2" /> Videos
          </TabsTrigger>
          <TabsTrigger value="premium" className="flex items-center">
            <Star className="mr-2" /> Premium
          </TabsTrigger>
          <TabsTrigger value="live" className="flex items-center">
            <Camera className="mr-2" /> Live
          </TabsTrigger>
          <TabsTrigger value="community" className="flex items-center">
            <Users className="mr-2" /> Community
          </TabsTrigger>
        </TabsList>

        <TabsContent value="tube">
          <TubeContent />
        </TabsContent>

        <TabsContent value="premium">
          <PremiumContentSection />
          {isCreator && <CreatorDashboard />}
        </TabsContent>

        <TabsContent value="live">
          <LiveStreamingSection />
          <ChaturbateSection />
        </TabsContent>

        <TabsContent value="community">
          <FanInteractionSection />
          <NFTMarketplace />
          <IndustryResources />
        </TabsContent>
      </Tabs>

      {/* Float Ad */}
      <Advertisement type="float" />
    </div>
  );
};

export default AdultContentPage;