import React from 'react';
import { MessageSquare, Heart, Star } from 'lucide-react';

const FanInteractionSection: React.FC = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md mb-8">
      <h2 className="text-2xl font-semibold mb-4 flex items-center">
        <Heart className="text-red-500 mr-2" />
        Fan Interaction
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="border p-4 rounded-lg">
          <h3 className="font-semibold mb-2 flex items-center">
            <MessageSquare className="text-blue-500 mr-2" size={20} />
            Private Messaging
          </h3>
          <p className="text-sm mb-2">Connect directly with your favorite creators!</p>
          <button className="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition duration-300">
            Start Chatting
          </button>
        </div>
        <div className="border p-4 rounded-lg">
          <h3 className="font-semibold mb-2 flex items-center">
            <Star className="text-yellow-500 mr-2" size={20} />
            Fan Clubs
          </h3>
          <p className="text-sm mb-2">Join exclusive communities for top-tier content!</p>
          <button className="bg-yellow-600 text-white px-4 py-2 rounded-full hover:bg-yellow-700 transition duration-300">
            Browse Fan Clubs
          </button>
        </div>
      </div>
    </div>
  );
};

export default FanInteractionSection;