import React, { useState } from 'react';
import { TrendingUp, DollarSign, AlertCircle } from 'lucide-react';
import { generateChatResponse } from '../api/chatGptApi';

const AIInvestmentAdvisor: React.FC = () => {
  const [investmentProfile, setInvestmentProfile] = useState({
    riskTolerance: 'moderate',
    investmentAmount: 1000,
    investmentDuration: 12,
  });
  const [advice, setAdvice] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setInvestmentProfile({ ...investmentProfile, [name]: value });
  };

  const getInvestmentAdvice = async () => {
    setIsLoading(true);
    try {
      const prompt = `Given an investment profile with risk tolerance: ${investmentProfile.riskTolerance}, investment amount: $${investmentProfile.investmentAmount}, and investment duration: ${investmentProfile.investmentDuration} months, provide a brief investment advice for the adult entertainment industry, including potential investment options and risk considerations.`;
      const response = await generateChatResponse(prompt);
      setAdvice(response);
    } catch (error) {
      console.error('Error gettinginvestment advice:', error);
      setAdvice('Sorry, I encountered an error while generating investment advice. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md mb-8">
      <h2 className="text-2xl font-semibold mb-4 flex items-center">
        <TrendingUp className="mr-2 text-purple-600" /> AI Investment Advisor
      </h2>
      <div className="space-y-4 mb-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Risk Tolerance</label>
          <select
            name="riskTolerance"
            value={investmentProfile.riskTolerance}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          >
            <option value="conservative">Conservative</option>
            <option value="moderate">Moderate</option>
            <option value="aggressive">Aggressive</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Investment Amount ($)</label>
          <input
            type="number"
            name="investmentAmount"
            value={investmentProfile.investmentAmount}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Investment Duration (months)</label>
          <input
            type="number"
            name="investmentDuration"
            value={investmentProfile.investmentDuration}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>
      </div>
      <button
        onClick={getInvestmentAdvice}
        disabled={isLoading}
        className="w-full bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 transition duration-300 flex items-center justify-center"
      >
        {isLoading ? (
          <>
            <DollarSign className="animate-spin mr-2" />
            Generating Advice...
          </>
        ) : (
          <>
            <DollarSign className="mr-2" />
            Get Investment Advice
          </>
        )}
      </button>
      {advice && (
        <div className="mt-6 p-4 bg-gray-100 rounded">
          <h3 className="font-semibold mb-2 flex items-center">
            <AlertCircle className="mr-2 text-purple-600" /> Investment Advice
          </h3>
          <p className="text-sm">{advice}</p>
        </div>
      )}
    </div>
  );
};

export default AIInvestmentAdvisor;