import React, { useState } from 'react';
import { Upload, Filter, Grid, List } from 'lucide-react';
import { GalleryItem } from '../../types/forum';
import ImageUploadModal from './ImageUploadModal';

const ForumGallery: React.FC = () => {
  const [showUpload, setShowUpload] = useState(false);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [items, setItems] = useState<GalleryItem[]>([
    {
      id: '1',
      title: 'Studio Photoshoot',
      imageUrl: 'https://images.unsplash.com/photo-1492684223066-81342ee5ff30',
      author: {
        id: '1',
        name: 'StudioPro',
        avatar: 'https://via.placeholder.com/40'
      },
      category: 'Photography',
      likes: 45,
      comments: 8,
      timestamp: new Date().toISOString()
    },
    // Add more gallery items
  ]);

  const handleUpload = (item: Partial<GalleryItem>) => {
    const newItem: GalleryItem = {
      id: Date.now().toString(),
      author: {
        id: 'current-user',
        name: 'Current User',
        avatar: 'https://via.placeholder.com/40'
      },
      likes: 0,
      comments: 0,
      timestamp: new Date().toISOString(),
      ...item
    };
    setItems([newItem, ...items]);
    setShowUpload(false);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <button
          onClick={() => setShowUpload(true)}
          className="bg-purple-600 text-white px-4 py-2 rounded-full hover:bg-purple-700 transition duration-300 flex items-center"
        >
          <Upload size={20} className="mr-2" />
          Upload Image
        </button>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setViewMode('grid')}
            className={`p-2 rounded ${viewMode === 'grid' ? 'bg-purple-100 text-purple-600' : ''}`}
          >
            <Grid size={20} />
          </button>
          <button
            onClick={() => setViewMode('list')}
            className={`p-2 rounded ${viewMode === 'list' ? 'bg-purple-100 text-purple-600' : ''}`}
          >
            <List size={20} />
          </button>
          <button className="flex items-center text-gray-600 hover:text-gray-800">
            <Filter size={20} className="mr-2" />
            Filter
          </button>
        </div>
      </div>

      <div className={`${
        viewMode === 'grid' 
          ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'
          : 'space-y-6'
      }`}>
        {items.map(item => (
          <div key={item.id} className="bg-white rounded-lg shadow-md overflow-hidden">
            <img
              src={item.imageUrl}
              alt={item.title}
              className="w-full h-64 object-cover"
            />
            <div className="p-4">
              <div className="flex items-center mb-2">
                <img
                  src={item.author.avatar}
                  alt={item.author.name}
                  className="w-8 h-8 rounded-full mr-2"
                />
                <div>
                  <h3 className="font-semibold">{item.author.name}</h3>
                  <p className="text-sm text-gray-500">
                    {new Date(item.timestamp).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <h2 className="text-lg font-bold mb-2">{item.title}</h2>
              {item.category && (
                <span className="inline-block bg-purple-100 text-purple-800 text-sm px-3 py-1 rounded-full">
                  {item.category}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>

      {showUpload && (
        <ImageUploadModal
          onClose={() => setShowUpload(false)}
          onSubmit={handleUpload}
        />
      )}
    </div>
  );
};

export default ForumGallery;