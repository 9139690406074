import React, { useState, useCallback } from 'react';
import debounce from 'lodash/debounce';

const ROICalculator: React.FC = () => {
  const [investment, setInvestment] = useState<number>(1000);
  const [duration, setDuration] = useState<number>(1);
  const [riskLevel, setRiskLevel] = useState<'Low' | 'Medium' | 'High'>('Medium');
  const [projectedReturn, setProjectedReturn] = useState<string>('0.00');

  const calculateROI = useCallback(
    debounce((inv: number, dur: number, risk: 'Low' | 'Medium' | 'High') => {
      const baseReturn = {
        Low: 0.08,
        Medium: 0.15,
        High: 0.25
      }[risk];

      const calculated = inv * Math.pow(1 + baseReturn, dur);
      setProjectedReturn(calculated.toFixed(2));
    }, 300),
    []
  );

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    setter: React.Dispatch<React.SetStateAction<any>>
  ) => {
    const value = e.target.type === 'number' ? Number(e.target.value) : e.target.value;
    setter(value);
    calculateROI(
      e.target.name === 'investment' ? value : investment,
      e.target.name === 'duration' ? value : duration,
      e.target.name === 'riskLevel' ? value as 'Low' | 'Medium' | 'High' : riskLevel
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h3 className="text-2xl font-semibold mb-4">ROI Calculator</h3>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Investment Amount ($)</label>
        <input
          type="number"
          name="investment"
          value={investment}
          onChange={(e) => handleInputChange(e, setInvestment)}
          className="w-full p-2 border rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Investment Duration (years)</label>
        <input
          type="number"
          name="duration"
          value={duration}
          onChange={(e) => handleInputChange(e, setDuration)}
          className="w-full p-2 border rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Risk Level</label>
        <select
          name="riskLevel"
          value={riskLevel}
          onChange={(e) => handleInputChange(e, setRiskLevel)}
          className="w-full p-2 border rounded"
        >
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
        </select>
      </div>
      <div className="mt-6">
        <h4 className="text-lg font-semibold mb-2">Projected Return</h4>
        <p className="text-3xl font-bold text-green-600">${projectedReturn}</p>
      </div>
    </div>
  );
};

export default ROICalculator;