import React from 'react';

interface Product {
  id: string;
  name: string;
  price: number;
  image: string;
  description: string;
}

interface AffiliateProduct extends Product {
  affiliateLink: string;
}

interface ProductCardProps {
  product: Product | AffiliateProduct;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  return (
    <div className="border rounded-lg p-4 flex flex-col">
      <img src={product.image} alt={product.name} className="w-full h-48 object-cover mb-4 rounded" />
      <h3 className="text-lg font-semibold mb-2">{product.name}</h3>
      <p className="text-gray-600 mb-2 flex-grow">{product.description}</p>
      <div className="flex justify-between items-center">
        <span className="text-xl font-bold">${product.price.toFixed(2)}</span>
        {'affiliateLink' in product ? (
          <a
            href={product.affiliateLink}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300"
          >
            Buy Now
          </a>
        ) : (
          <button className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 transition duration-300">
            Add to Cart
          </button>
        )}
      </div>
    </div>
  );
};

export default ProductCard;