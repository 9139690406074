import React from 'react';
import { ExternalLink, Instagram, Mail, Video, MessageCircle, Globe, Heart, DollarSign } from 'lucide-react';
import Advertisement from '../components/Advertisements';

const GabrielleGrowthPage: React.FC = () => {
  const socialLinks = [
    {
      name: 'Newsletter',
      url: 'https://gabriellegrowth-newsletter.beehiiv.com/',
      icon: <Mail className="w-5 h-5" />,
      color: 'bg-purple-600 hover:bg-purple-700'
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/liliana.gabrielle/',
      icon: <Instagram className="w-5 h-5" />,
      color: 'bg-pink-600 hover:bg-pink-700'
    },
    {
      name: 'TikTok',
      url: 'https://www.tiktok.com/@gabriellegrowth',
      icon: <Video className="w-5 h-5" />,
      color: 'bg-black hover:bg-gray-900'
    },
    {
      name: 'Threads',
      url: 'https://www.threads.net/@liliana.gabrielle',
      icon: <MessageCircle className="w-5 h-5" />,
      color: 'bg-gray-900 hover:bg-gray-800'
    },
    {
      name: 'Twitter',
      url: 'https://x.com/Liliana_Gabri',
      icon: <ExternalLink className="w-5 h-5" />,
      color: 'bg-blue-600 hover:bg-blue-700'
    },
    {
      name: 'Beacons',
      url: 'https://beacons.ai/liliana.gabrielle',
      icon: <Globe className="w-5 h-5" />,
      color: 'bg-green-600 hover:bg-green-700'
    },
    {
      name: 'Fanvue',
      url: 'https://www.fanvue.com/lil.gabreielle?free_trial=5018b7cc-2f72-4302-8cf5-3e27ef076a16',
      icon: <Heart className="w-5 h-5" />,
      color: 'bg-red-600 hover:bg-red-700'
    },
    {
      name: 'Fansly',
      url: 'https://fans.ly/LilianaGabrielle',
      icon: <Heart className="w-5 h-5" />,
      color: 'bg-pink-500 hover:bg-pink-600'
    },
    {
      name: 'Patreon',
      url: 'https://patreon.com/LilianaGabrielle',
      icon: <DollarSign className="w-5 h-5" />,
      color: 'bg-orange-600 hover:bg-orange-700'
    }
  ];

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold mb-8 text-center text-purple-800">GabrielleGrowth</h1>

      {/* Top Banner Ad */}
      <Advertisement type="banner" />

      <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-md">
        <p className="text-lg mb-6">
          Join GabrielleGrowth for exclusive insights, investment opportunities, and expert advice in the world of adult entertainment and business growth.
        </p>

        {/* Social Links Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
          {socialLinks.map((link, index) => (
            <a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className={`${link.color} text-white px-6 py-3 rounded-full inline-flex items-center justify-center transition duration-300`}
            >
              {link.icon}
              <span className="ml-2">{link.name}</span>
            </a>
          ))}
        </div>

        {/* Newsletter Highlight */}
        <div className="bg-purple-100 p-6 rounded-lg mb-8">
          <h2 className="text-2xl font-semibold mb-4">Subscribe to Our Newsletter</h2>
          <p className="mb-6">
            Stay updated with the latest trends, investment tips, and success stories in the industry. Our newsletter provides valuable information to help you make informed decisions and grow your wealth.
          </p>
          <a 
            href="https://gabriellegrowth-newsletter.beehiiv.com/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="bg-purple-600 text-white px-6 py-3 rounded-full inline-flex items-center hover:bg-purple-700 transition duration-300"
          >
            <Mail size={20} className="mr-2" />
            Subscribe to GabrielleGrowth Newsletter
          </a>
        </div>

        {/* Display Ad */}
        <Advertisement type="display" />
      </div>

      {/* Native Ad */}
      <Advertisement type="native" />

      {/* Float Ad */}
      <Advertisement type="float" />
    </div>
  );
};

export default GabrielleGrowthPage;