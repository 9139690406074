import React, { useState } from 'react';
import { Activity, MessageSquare, Heart, Image, User } from 'lucide-react';
import { ForumActivity } from '../../types/forum';

const ForumActivityFeed: React.FC = () => {
  const [activities] = useState<ForumActivity[]>([
    {
      id: '1',
      type: 'post',
      user: {
        id: '1',
        name: 'Liliana G.',
        avatar: 'https://via.placeholder.com/40'
      },
      content: 'created a new post',
      target: 'Getting Started in Content Creation',
      timestamp: new Date().toISOString()
    },
    // Add more activities
  ]);

  const getActivityIcon = (type: string) => {
    switch (type) {
      case 'post':
        return <MessageSquare className="text-blue-500" />;
      case 'like':
        return <Heart className="text-red-500" />;
      case 'image':
        return <Image className="text-green-500" />;
      case 'follow':
        return <User className="text-purple-500" />;
      default:
        return <Activity className="text-gray-500" />;
    }
  };

  return (
    <div className="space-y-6">
      {activities.map(activity => (
        <div key={activity.id} className="bg-white p-4 rounded-lg shadow-md flex items-start">
          <div className="mr-4">
            {getActivityIcon(activity.type)}
          </div>
          <div className="flex-grow">
            <div className="flex items-center mb-2">
              <img
                src={activity.user.avatar}
                alt={activity.user.name}
                className="w-8 h-8 rounded-full mr-2"
              />
              <div>
                <span className="font-semibold">{activity.user.name}</span>
                <span className="text-gray-600"> {activity.content} </span>
                <span className="font-semibold">{activity.target}</span>
              </div>
            </div>
            <p className="text-sm text-gray-500">
              {new Date(activity.timestamp).toLocaleString()}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ForumActivityFeed;