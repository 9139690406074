import React from 'react';
import { Shield, AlertTriangle } from 'lucide-react';

interface AgeGateProps {
  onVerification: (isVerified: boolean) => void;
}

const AgeGate: React.FC<AgeGateProps> = ({ onVerification }) => {
  const handleVerification = (isVerified: boolean) => {
    localStorage.setItem('age_verified', isVerified.toString());
    onVerification(isVerified);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg max-w-md w-full animate-slideUp">
        <div className="flex items-center justify-center mb-6">
          <Shield className="w-16 h-16 text-red-600" />
        </div>
        
        <h2 className="text-2xl font-bold mb-4 text-center">Age Verification Required</h2>
        
        <div className="bg-red-100 border-l-4 border-red-500 p-4 mb-6">
          <div className="flex items-center">
            <AlertTriangle className="text-red-500 mr-2" />
            <p className="text-red-700">
              This website contains adult content. You must be 18 years or older to enter.
            </p>
          </div>
        </div>

        <div className="space-y-4">
          <button
            onClick={() => handleVerification(true)}
            className="w-full bg-purple-600 text-white px-6 py-3 rounded-full hover:bg-purple-700 transition duration-300 font-semibold"
          >
            I am 18 or older
          </button>
          
          <button
            onClick={() => handleVerification(false)}
            className="w-full bg-gray-200 text-gray-800 px-6 py-3 rounded-full hover:bg-gray-300 transition duration-300 font-semibold"
          >
            I am under 18
          </button>
        </div>

        <p className="mt-6 text-xs text-gray-500 text-center">
          By entering this site, you confirm that you are of legal age in your jurisdiction to view adult content 
          and agree to our Terms of Service and Privacy Policy.
        </p>
      </div>
    </div>
  );
};

export default AgeGate;