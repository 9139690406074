import React from 'react';
import { Video, Gift, MessageCircle } from 'lucide-react';

const LiveStreamingSection: React.FC = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md mb-8">
      <h2 className="text-2xl font-semibold mb-4 flex items-center">
        <Video className="text-red-500 mr-2" />
        Live Streaming
      </h2>
      <div className="relative">
        <img
          src="https://via.placeholder.com/800x450?text=Live+Stream"
          alt="Live Stream"
          className="w-full rounded-lg"
        />
        <div className="absolute bottom-4 left-4 flex space-x-2">
          <button className="bg-purple-600 text-white px-4 py-2 rounded-full hover:bg-purple-700 transition duration-300 flex items-center">
            <Gift size={16} className="mr-2" />
            Send Tip
          </button>
          <button className="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition duration-300 flex items-center">
            <MessageCircle size={16} className="mr-2" />
            Chat
          </button>
        </div>
      </div>
      <div className="mt-4">
        <h3 className="font-semibold">Upcoming Live Shows</h3>
        <ul className="mt-2 space-y-2">
          <li>Model A - 8:00 PM</li>
          <li>Model B - 9:30 PM</li>
          <li>Model C - 11:00 PM</li>
        </ul>
      </div>
    </div>
  );
};

export default LiveStreamingSection;