import React, { useState, useEffect } from 'react';
import { ExternalLink } from 'lucide-react';

interface NewsItem {
  id: string;
  title: string;
  summary: string;
  url: string;
  source: string;
  publishedAt: string;
}

const FinancialNews: React.FC = () => {
  const [news, setNews] = useState<NewsItem[]>([]);

  useEffect(() => {
    const fetchNews = async () => {
      // In a real application, this would be an API call
      const mockNews: NewsItem[] = [
        {
          id: '1',
          title: 'Crypto Market Surges as Institutional Investors Show Increased Interest',
          summary: 'Bitcoin and Ethereum see significant gains as more institutions add cryptocurrencies to their portfolios.',
          url: 'https://example.com/news/1',
          source: 'CryptoNews',
          publishedAt: '2024-03-15T10:30:00Z',
        },
        {
          id: '2',
          title: 'Adult Content Creators See Record Earnings Amid Platform Innovations',
          summary: 'New monetization features on adult content platforms lead to increased earnings for creators.',
          url: 'https://example.com/news/2',
          source: 'AdultIndustryToday',
          publishedAt: '2024-03-14T14:45:00Z',
        },
        {
          id: '3',
          title: 'Tech Stocks Rally as AI Advancements Drive Market Optimism',
          summary: 'Major tech companies report breakthroughs in AI technology, leading to a surge in stock prices.',
          url: 'https://example.com/news/3',
          source: 'TechInvestor',
          publishedAt: '2024-03-13T09:15:00Z',
        },
      ];
      setNews(mockNews);
    };

    fetchNews();
  }, []);

  return (
    <div className="bg-white p-6 rounded-lg shadow-md mb-8">
      <h3 className="text-2xl font-semibold mb-4">Financial News</h3>
      <div className="space-y-6">
        {news.map((item) => (
          <div key={item.id} className="border-b pb-4 last:border-b-0">
            <h4 className="text-lg font-medium mb-2">{item.title}</h4>
            <p className="text-gray-600 mb-2">{item.summary}</p>
            <div className="flex justify-between items-center text-sm text-gray-500">
              <span>{item.source}</span>
              <span>{new Date(item.publishedAt).toLocaleDateString()}</span>
            </div>
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-purple-600 hover:text-purple-800 flex items-center mt-2"
            >
              Read more <ExternalLink size={16} className="ml-1" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FinancialNews;