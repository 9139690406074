import React from 'react';
import { Link } from 'react-router-dom';

interface ActionButtonProps {
  to: string;
  icon: React.ReactNode;
  title: string;
  description: string;
}

const ActionButton: React.FC<ActionButtonProps> = ({ to, icon, title, description }) => {
  return (
    <Link to={to} className="service-card bg-purple-600 text-white p-6 rounded-lg shadow-md hover:shadow-xl transition-all">
      <div className="flex items-center mb-4">
        <div className="w-12 h-12 flex items-center justify-center">
          {icon}
        </div>
        <h3 className="text-xl font-bold ml-2">{title}</h3>
      </div>
      <p>{description}</p>
    </Link>
  );
};

export default ActionButton;