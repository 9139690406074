import React from 'react';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy for StudioMystique LLC</h1>
      <p className="mb-4">Effective Date: [Insert Date]</p>
      
      <section className="mb-8">
        <p>StudioMystique LLC ("we," "us," or "our") is committed to protecting the privacy and personal information of our users and clients. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide to us through our website, services, and interactions.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
        <ul className="list-disc list-inside space-y-2">
          <li><strong>Personal Information:</strong> We may collect personal information such as your name, email address, payment details, and any other information you voluntarily provide when subscribing to our services, making transactions, or interacting with our site.</li>
          <li><strong>Automatically Collected Information:</strong> We use cookies and similar tracking technologies to collect information about your device, browsing actions, and patterns. This includes your IP address, browser type, operating system, and access times.</li>
          <li><strong>Financial Information:</strong> If you engage in financial transactions through our platform, we collect information necessary to process those transactions, including but not limited to bank account details, transaction history, and credit card information.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
        <ul className="list-disc list-inside space-y-2">
          <li>To provide, operate, and maintain our services.</li>
          <li>To process your transactions, manage your subscriptions, and fulfill your requests.</li>
          <li>To communicate with you regarding your account, updates, promotional offers, and site information.</li>
          <li>To improve the quality of our services, troubleshoot issues, and protect against fraud or unauthorized access.</li>
          <li>To comply with legal obligations, resolve disputes, and enforce our agreements.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">3. Disclosure of Your Information</h2>
        <p>We may disclose your personal information to third parties for the following purposes:</p>
        <ul className="list-disc list-inside space-y-2">
          <li><strong>Service Providers:</strong> We may share information with vendors, consultants, and other service providers who require access to such information to carry out work on our behalf.</li>
          <li><strong>Legal Compliance:</strong> We may disclose information where required by law, including compliance with legal proceedings, court orders, or government regulations.</li>
          <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our business, personal information may be transferred as part of the transaction.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">4. Data Security</h2>
        <p>We implement strict security measures to protect your personal and financial information. These measures include encryption, firewalls, and secure data storage systems. However, no method of transmission or storage is completely secure, and we cannot guarantee absolute security.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">5. Your Rights</h2>
        <p>You have the right to:</p>
        <ul className="list-disc list-inside space-y-2">
          <li>Access, correct, or delete your personal information.</li>
          <li>Opt-out of marketing communications by following the unsubscribe instructions in the emails you receive.</li>
          <li>Request that we stop processing your information for certain purposes.</li>
          <li>Withdraw consent at any time where processing is based on consent.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">6. Children's Privacy</h2>
        <p>Our services are intended for individuals over the age of 18. We do not knowingly collect or solicit personal information from anyone under 18. If we become aware that a child under 18 has provided us with personal information, we will take steps to delete such information.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">7. International Data Transfers</h2>
        <p>Your information may be transferred to and processed in countries other than your own. We will take appropriate measures to protect your personal information in accordance with this Privacy Policy.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">8. Changes to This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top of this policy.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">9. International Privacy Laws</h2>
        <p>In addition to complying with GDPR and CCPA, Studio Mystique follows other international privacy laws:</p>
        <ul className="list-disc list-inside space-y-2">
          <li>Canada's PIPEDA (Personal Information Protection and Electronic Documents Act) requires organizations to obtain consent from individuals before collecting or disclosing personal information and to safeguard that information.</li>
          <li>Brazil's LGPD (Lei Geral de Proteção de Dados Pessoais) is similar to GDPR and applies to data processing activities of Brazilian residents.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">10. COPPA (Children's Online Privacy Protection Act)</h2>
        <p>Studio Mystique is intended for users above the age of 18 and complies with COPPA by not collecting or storing personal information of individuals under 13. We have implemented strict policies to ensure that no underage users access the platform.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">11. Financial Conduct Authority (FCA)</h2>
        <p>For users in the UK and Europe, Studio Mystique ensures compliance with FCA rules, which regulate financial services and products to ensure consumer protection, market integrity, and competition. If the platform offers financial products or services, we ensure the regulatory framework aligns with FCA guidelines, including how we promote and market investments.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Intellectual Property Protection and Compliance</h2>
        <h3 className="text-xl font-semibold mb-2">12. Digital Millennium Copyright Act (DMCA)</h3>
        <p>Studio Mystique complies with the DMCA by providing a clear process for copyright holders to submit takedown notices for content that infringes on their copyrights. We promptly respond to valid claims of infringement and take action to remove infringing material.</p>
        
        <h3 className="text-xl font-semibold mt-4 mb-2">13. Trademark Compliance</h3>
        <p>We protect the Studio Mystique brand, trademarks, and logos under U.S. and international trademark laws. Unauthorized use of our brand identity is strictly prohibited, and legal action will be taken against infringement.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at:</p>
        <p>Email: privacy@studiomystique.com</p>
        <p>Address: [Insert Studio Mystique's physical address]</p>
      </section>
    </div>
  );
};

export default PrivacyPolicyPage;