import React from 'react';
import { BarChart2, Users, DollarSign } from 'lucide-react';

const CreatorDashboard: React.FC = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md mb-8">
      <h2 className="text-2xl font-semibold mb-4">Creator Dashboard</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="bg-purple-100 p-4 rounded-lg">
          <BarChart2 className="text-purple-600 mb-2" size={24} />
          <h3 className="font-semibold">Content Performance</h3>
          <p className="text-2xl font-bold">10,234 views</p>
        </div>
        <div className="bg-blue-100 p-4 rounded-lg">
          <Users className="text-blue-600 mb-2" size={24} />
          <h3 className="font-semibold">Fan Growth</h3>
          <p className="text-2xl font-bold">+523 this week</p>
        </div>
        <div className="bg-green-100 p-4 rounded-lg">
          <DollarSign className="text-green-600 mb-2" size={24} />
          <h3 className="font-semibold">Earnings</h3>
          <p className="text-2xl font-bold">$1,234.56</p>
        </div>
      </div>
      <button className="mt-4 bg-purple-600 text-white px-4 py-2 rounded-full hover:bg-purple-700 transition duration-300">
        View Detailed Analytics
      </button>
    </div>
  );
};

export default CreatorDashboard;