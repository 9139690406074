import React from 'react';
import { Camera, DollarSign, TrendingUp, Star, Sparkles } from 'lucide-react';

const WelcomeSection: React.FC = () => {
  return (
    <div className="relative min-h-[600px] flex items-center justify-center overflow-hidden">
      {/* Background with gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-r from-purple-900/90 to-pink-900/90">
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1492684223066-81342ee5ff30')] bg-cover bg-center mix-blend-overlay"></div>
      </div>

      {/* Beta Badge */}
      <div className="absolute top-4 right-4 bg-gradient-to-r from-purple-600 to-pink-600 text-white px-4 py-1 rounded-full text-sm font-semibold flex items-center">
        <Sparkles className="w-4 h-4 mr-1" />
        Soft Launch Beta
      </div>

      {/* Content */}
      <div className="relative z-10 text-center text-white max-w-4xl mx-auto px-4">
        <h1 className="text-6xl md:text-7xl font-bold mb-2 font-audiowide">
          <span className="gradient-text">Studio Mystique</span>
        </h1>
        <h2 className="mb-8">
          <span className="text-4xl md:text-5xl font-bold font-orbitron bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-pink-500 animate-pulse">
            Dirty Rich!
          </span>
          <span className="block text-2xl md:text-3xl mt-4 font-orbitron text-gray-200">
            Where Adult Luxury and Fun Meet Exquisite Opportunity
          </span>
        </h2>

        {/* Beta Message */}
        <div className="mb-8 bg-white/10 backdrop-blur-sm p-4 rounded-lg inline-block">
          <p className="text-sm">
            🎉 Join our exclusive beta launch and get early access to premium features
          </p>
        </div>

        <div className="flex flex-col md:flex-row justify-center gap-4 mb-12">
          <div className="bg-white/10 backdrop-blur-sm p-6 rounded-lg flex-1 hover:bg-white/20 transition-all duration-300">
            <Camera className="w-8 h-8 text-pink-400 mx-auto mb-2" />
            <h3 className="text-xl font-semibold mb-2">Content Creation</h3>
            <p className="text-gray-200">Professional content creation and management services for adult entertainers</p>
            <span className="inline-block mt-2 text-xs bg-purple-500/50 px-2 py-1 rounded-full">Beta Access</span>
          </div>

          <div className="bg-white/10 backdrop-blur-sm p-6 rounded-lg flex-1 hover:bg-white/20 transition-all duration-300">
            <DollarSign className="w-8 h-8 text-green-400 mx-auto mb-2" />
            <h3 className="text-xl font-semibold mb-2">Investment</h3>
            <p className="text-gray-200">Diverse investment opportunities in adult entertainment and related industries</p>
            <span className="inline-block mt-2 text-xs bg-purple-500/50 px-2 py-1 rounded-full">Coming Soon</span>
          </div>

          <div className="bg-white/10 backdrop-blur-sm p-6 rounded-lg flex-1 hover:bg-white/20 transition-all duration-300">
            <TrendingUp className="w-8 h-8 text-blue-400 mx-auto mb-2" />
            <h3 className="text-xl font-semibold mb-2">Growth</h3>
            <p className="text-gray-200">Strategic business development and career advancement solutions</p>
            <span className="inline-block mt-2 text-xs bg-purple-500/50 px-2 py-1 rounded-full">Beta Access</span>
          </div>

          <div className="bg-white/10 backdrop-blur-sm p-6 rounded-lg flex-1 hover:bg-white/20 transition-all duration-300">
            <Star className="w-8 h-8 text-yellow-400 mx-auto mb-2" />
            <h3 className="text-xl font-semibold mb-2">Premium</h3>
            <p className="text-gray-200">Exclusive content and VIP experiences for discerning clients</p>
            <span className="inline-block mt-2 text-xs bg-purple-500/50 px-2 py-1 rounded-full">Limited Beta</span>
          </div>
        </div>

        <div className="flex justify-center gap-4">
          <button className="bg-purple-600 text-white px-8 py-3 rounded-full hover:bg-purple-700 transition duration-300 transform hover:scale-105">
            Join Beta
          </button>
          <button className="bg-white/10 backdrop-blur-sm text-white px-8 py-3 rounded-full hover:bg-white/20 transition duration-300 transform hover:scale-105">
            Learn More
          </button>
        </div>

        {/* Early Access Incentive */}
        <div className="mt-8 text-sm">
          <p className="text-gray-300">
            🌟 Early beta users receive lifetime VIP status and exclusive benefits
          </p>
        </div>
      </div>
    </div>
  );
};

export default WelcomeSection;