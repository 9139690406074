import OpenAI from 'openai';

const apiKey = import.meta.env.VITE_OPENAI_API_KEY;

const openai = new OpenAI({
  apiKey: apiKey || 'placeholder-key',
  dangerouslyAllowBrowser: true
});

export const generateChatResponse = async (prompt: string): Promise<string> => {
  if (!apiKey) {
    console.warn('OpenAI API key not configured');
    return 'Chat features are currently unavailable';
  }

  try {
    const response = await openai.chat.completions.create({
      model: 'gpt-3.5-turbo',
      messages: [{ role: 'user', content: prompt }],
      max_tokens: 150,
      temperature: 0.7,
    });

    return response.choices[0]?.message?.content || 'No response generated';
  } catch (error) {
    console.error('Error calling ChatGPT API:', error);
    return 'An error occurred while generating a response';
  }
};