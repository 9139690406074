import React from 'react';
import { Play, Clock, Eye } from 'lucide-react';

interface Video {
  id: string;
  title: string;
  description: string;
  thumbnail: string;
  duration: string;
  views: number;
  platform: string;
  embedUrl: string;
  categories: string[];
  tags: string[];
  uploadDate: string;
}

interface VideoGridProps {
  videos: Video[];
}

const VideoGrid: React.FC<VideoGridProps> = ({ videos }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
      {videos.map((video) => (
        <div
          key={video.id}
          className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition duration-300"
        >
          <div className="relative group">
            <img
              src={video.thumbnail}
              alt={video.title}
              className="w-full aspect-video object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
              <Play className="text-white w-12 h-12" />
            </div>
            <div className="absolute bottom-2 right-2 bg-black bg-opacity-75 text-white px-2 py-1 rounded text-sm flex items-center">
              <Clock className="w-4 h-4 mr-1" />
              {video.duration}
            </div>
          </div>
          <div className="p-4">
            <h3 className="font-semibold mb-2 line-clamp-2">{video.title}</h3>
            <div className="flex items-center justify-between text-sm text-gray-600">
              <span className="flex items-center">
                <Eye className="w-4 h-4 mr-1" />
                {video.views.toLocaleString()} views
              </span>
              <span className="bg-gray-100 px-2 py-1 rounded">
                {video.platform}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default VideoGrid;