import React from 'react';
import { Lock, Star } from 'lucide-react';

const PremiumContentSection: React.FC = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md mb-8">
      <h2 className="text-2xl font-semibold mb-4 flex items-center">
        <Star className="text-yellow-400 mr-2" />
        Premium Content
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {[1, 2, 3].map((item) => (
          <div key={item} className="relative group">
            <img
              src={`https://via.placeholder.com/300x200?text=Premium+Content+${item}`}
              alt={`Premium Content ${item}`}
              className="w-full h-48 object-cover rounded-lg"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <Lock className="text-white" size={24} />
              <span className="text-white ml-2">Unlock Premium</span>
            </div>
          </div>
        ))}
      </div>
      <button className="mt-4 bg-purple-600 text-white px-4 py-2 rounded-full hover:bg-purple-700 transition duration-300">
        Upgrade to Premium
      </button>
    </div>
  );
};

export default PremiumContentSection;