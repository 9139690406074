import React from 'react';
import { Link } from 'react-router-dom';

const PortfolioDiversification: React.FC = () => {
  return (
    <div className="bg-white p-8 rounded-lg shadow-md mb-12">
      <h2 className="text-3xl font-bold mb-4 text-purple-800">Build a Resilient Investment Portfolio</h2>
      <p className="text-lg mb-4">
        Learn how to balance high-risk, high-reward opportunities with stable, income-generating investments to create a robust portfolio.
      </p>
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Diversification Strategies</h3>
        <ul className="list-disc list-inside">
          <li>Balancing digital assets (NFTs, crypto) with traditional businesses</li>
          <li>Mixing short-term and long-term investment horizons</li>
          <li>Geographical diversification across different markets</li>
          <li>Sector diversification within and beyond adult entertainment</li>
        </ul>
      </div>
      <div className="flex space-x-4">
        <Link to="/diversification-guide" className="bg-purple-600 text-white px-6 py-3 rounded-full hover:bg-purple-700 transition duration-300">
          Read Our Diversification Guide
        </Link>
        <Link to="/portfolio-analysis" className="bg-gray-200 text-gray-800 px-6 py-3 rounded-full hover:bg-gray-300 transition duration-300">
          Get a Free Portfolio Analysis
        </Link>
      </div>
    </div>
  );
};

export default PortfolioDiversification;