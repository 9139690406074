import React, { useState, useEffect } from 'react';
import { User, Camera, Briefcase, Star, DollarSign, TrendingUp, Trophy, Gift, Save, Upload } from 'lucide-react';

interface Character {
  id: string;
  role: 'model' | 'photographer' | 'manager';
  name: string;
  level: number;
  experience: number;
  skills: {
    charisma: number;
    creativity: number;
    business: number;
  };
  brand: number;
  money: number;
  energy: number;
  gameTokens: number;
  appearance: {
    hairColor: string;
    eyeColor: string;
    bodyType: string;
  };
}

interface Event {
  id: string;
  title: string;
  description: string;
  minLevel: number;
  roleSpecific?: 'model' | 'photographer' | 'manager';
  choices: {
    text: string;
    outcome: (character: Character) => { message: string; effects: Partial<Character> };
  }[];
}

interface Achievement {
  id: string;
  name: string;
  description: string;
  condition: (character: Character) => boolean;
  reward: number;
  unlocked: boolean;
}

const AdultIndustryRPG: React.FC = () => {
  const [character, setCharacter] = useState<Character | null>(null);
  const [currentEvent, setCurrentEvent] = useState<Event | null>(null);
  const [gameLog, setGameLog] = useState<string[]>([]);
  const [achievements, setAchievements] = useState<Achievement[]>([
    // ... (previous achievements)
    {
      id: '4',
      name: 'Social Media Sensation',
      description: 'Reach a brand value of 100',
      condition: (char) => char.brand >= 100,
      reward: 300,
      unlocked: false,
    },
    {
      id: '5',
      name: 'Millionaire',
      description: 'Accumulate 1,000,000 in-game money',
      condition: (char) => char.money >= 1000000,
      reward: 500,
      unlocked: false,
    },
  ]);
  const [leaderboard, setLeaderboard] = useState<{ name: string; score: number }[]>([]);

  useEffect(() => {
    if (!character) {
      setGameLog(['Welcome to the Adult Entertainment Industry RPG!', 'Create your character to begin.']);
    } else {
      checkAchievements();
      updateLeaderboard();
    }
  }, [character]);

  const createCharacter = (role: 'model' | 'photographer' | 'manager') => {
    const newCharacter: Character = {
      id: `player_${Date.now()}`,
      role,
      name: '',
      level: 1,
      experience: 0,
      skills: {
        charisma: role === 'model' ? 3 : 1,
        creativity: role === 'photographer' ? 3 : 1,
        business: role === 'manager' ? 3 : 1,
      },
      brand: 10,
      money: 1000,
      energy: 100,
      gameTokens: 0,
      appearance: {
        hairColor: '',
        eyeColor: '',
        bodyType: '',
      },
    };
    setCharacter(newCharacter);
  };

  const finalizeCharacter = (name: string, appearance: Character['appearance']) => {
    if (character) {
      const updatedCharacter = { ...character, name, appearance };
      setCharacter(updatedCharacter);
      setGameLog([...gameLog, `Welcome, ${name}! Your journey as a ${character.role} begins!`]);
      generateEvent(updatedCharacter);
    }
  };

  const generateEvent = (currentCharacter: Character) => {
    const events: Event[] = [
      // ... (previous events)
      {
        id: '3',
        title: 'Social Media Challenge',
        description: 'A trending challenge is sweeping social media. How do you participate?',
        minLevel: 3,
        choices: [
          {
            text: 'Create an innovative, daring post',
            outcome: (char) => ({
              message: char.skills.creativity > 3 ? 'Your post goes viral!' : 'Your post gets mixed reactions.',
              effects: char.skills.creativity > 3 
                ? { brand: char.brand + 10, experience: char.experience + 100, gameTokens: char.gameTokens + 5 } 
                : { brand: char.brand + 2, experience: char.experience + 30 }
            })
          },
          {
            text: 'Play it safe with a conventional post',
            outcome: (char) => ({
              message: 'Your post gets some engagement, but nothing spectacular.',
              effects: { brand: char.brand + 3, experience: char.experience + 50 }
            })
          },
          {
            text: 'Ignore the challenge',
            outcome: (char) => ({
              message: 'You miss out on potential exposure but save time and energy.',
              effects: { energy: char.energy + 10 }
            })
          }
        ]
      },
      {
        id: '4',
        title: 'Exclusive Contract Offer',
        description: 'A major adult entertainment company offers you an exclusive contract. What do you do?',
        minLevel: 5,
        choices: [
          {
            text: 'Accept the offer',
            outcome: (char) => ({
              message: 'You secure a stable income but limit your independence.',
              effects: { money: char.money + 5000, brand: char.brand + 5, experience: char.experience + 200 }
            })
          },
          {
            text: 'Negotiate for better terms',
            outcome: (char) => ({
              message: char.skills.business > 4 ? 'Your negotiation skills pay off!' : 'The company withdraws their offer.',
              effects: char.skills.business > 4 
                ? { money: char.money + 10000, brand: char.brand + 10, experience: char.experience + 300 } 
                : { experience: char.experience + 50 }
            })
          },
          {
            text: 'Decline and stay independent',
            outcome: (char) => ({
              message: 'You maintain your freedom but miss out on guaranteed income.',
              effects: { brand: char.brand + 2, experience: char.experience + 100 }
            })
          }
        ]
      },
    ];

    const eligibleEvents = events.filter(event => 
      currentCharacter.level >= event.minLevel && 
      (!event.roleSpecific || event.roleSpecific === currentCharacter.role)
    );
    setCurrentEvent(eligibleEvents[Math.floor(Math.random() * eligibleEvents.length)]);
  };

  const handleChoice = (choice: typeof currentEvent.choices[0]) => {
    if (!character || !currentEvent) return;

    const outcome = choice.outcome(character);
    const updatedCharacter = { ...character, ...outcome.effects };

    setCharacter(updatedCharacter);
    setGameLog([...gameLog, outcome.message]);
    setCurrentEvent(null);

    // Check for level up
    if (updatedCharacter.experience >= updatedCharacter.level * 100) {
      updatedCharacter.level += 1;
      updatedCharacter.experience = 0;
      updatedCharacter.gameTokens += 10;
      setGameLog([...gameLog, outcome.message, `Congratulations! You've reached level ${updatedCharacter.level}!`, `You earned 10 game tokens!`]);
    }

    checkAchievements();
    updateLeaderboard();

    // Generate next event
    setTimeout(() => generateEvent(updatedCharacter), 1000);
  };

  const checkAchievements = () => {
    if (!character) return;

    const updatedAchievements = achievements.map(achievement => {
      if (!achievement.unlocked && achievement.condition(character)) {
        setGameLog([...gameLog, `Achievement Unlocked: ${achievement.name}`, `You earned ${achievement.reward} Studio Mystique tokens!`]);
        character.gameTokens += achievement.reward;
        return { ...achievement, unlocked: true };
      }
      return achievement;
    });

    setAchievements(updatedAchievements);
  };

  const convertTokens = () => {
    if (!character) return;

    const conversionRate = 0.1;
    const studioMystiqueTokens = Math.floor(character.gameTokens * conversionRate);
    
    setCharacter({
      ...character,
      gameTokens: 0
    });

    setGameLog([...gameLog, `Converted ${character.gameTokens} game tokens to ${studioMystiqueTokens} Studio Mystique tokens!`]);

    // Placeholder for API call to add tokens to user's Studio Mystique account
    console.log(`Add ${studioMystiqueTokens} tokens to user's Studio Mystique account`);
  };

  const updateLeaderboard = () => {
    if (!character) return;

    const score = character.level * 100 + character.brand + character.money / 100;
    const newLeaderboard = [...leaderboard, { name: character.name, score }]
      .sort((a, b) => b.score - a.score)
      .slice(0, 10);
    setLeaderboard(newLeaderboard);
  };

  const saveGame = () => {
    if (!character) return;
    localStorage.setItem('adultIndustryRPG_saveGame', JSON.stringify(character));
    setGameLog([...gameLog, 'Game saved successfully!']);
  };

  const loadGame = () => {
    const savedGame = localStorage.getItem('adultIndustryRPG_saveGame');
    if (savedGame) {
      const loadedCharacter = JSON.parse(savedGame);
      setCharacter(loadedCharacter);
      setGameLog([...gameLog, 'Game loaded successfully!']);
      generateEvent(loadedCharacter);
    } else {
      setGameLog([...gameLog, 'No saved game found.']);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Adult Entertainment Industry RPG</h2>
      
      {!character ? (
        <div className="space-y-4">
          <p>Choose your role:</p>
          <div className="flex space-x-4">
            <button onClick={() => createCharacter('model')} className="flex items-center px-4 py-2 bg-pink-500 text-white rounded hover:bg-pink-600">
              <User className="mr-2" /> Model
            </button>
            <button onClick={() => createCharacter('photographer')} className="flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
              <Camera className="mr-2" /> Photographer
            </button>
            <button onClick={() => createCharacter('manager')} className="flex items-center px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">
              <Briefcase className="mr-2" /> Manager
            </button>
          </div>
        </div>
      ) : character.name === '' ? (
        <div className="space-y-4">
          <h3 className="text-xl font-semibold">Customize Your Character</h3>
          <input
            type="text"
            placeholder="Character Name"
            className="w-full p-2 border rounded"
            onChange={(e) => setCharacter({ ...character, name: e.target.value })}
          />
          <select
            className="w-full p-2 border rounded"
            onChange={(e) => setCharacter({ ...character, appearance: { ...character.appearance, hairColor: e.target.value } })}
          >
            <option value="">Select Hair Color</option>
            <option value="blonde">Blonde</option>
            <option value="brunette">Brunette</option>
            <option value="redhead">Redhead</option>
            <option value="black">Black</option>
          </select>
          <select
            className="w-full p-2 border rounded"
            onChange={(e) => setCharacter({ ...character, appearance: { ...character.appearance, eyeColor: e.target.value } })}
          >
            <option value="">Select Eye Color</option>
            <option value="blue">Blue</option>
            <option value="green">Green</option>
            <option value="brown">Brown</option>
            <option value="hazel">Hazel</option>
          </select>
          <select
            className="w-full p-2 border rounded"
            onChange={(e) => setCharacter({ ...character, appearance: { ...character.appearance, bodyType: e.target.value } })}
          >
            <option value="">Select Body Type</option>
            <option value="slim">Slim</option>
            <option value="athletic">Athletic</option>
            <option value="curvy">Curvy</option>
            <option value="muscular">Muscular</option>
          </select>
          <button
            onClick={() => finalizeCharacter(character.name, character.appearance)}
            className="w-full bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 transition duration-300"
          >
            Start Your Journey
          </button>
        </div>
      ) : (
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="text-xl font-semibold">{character.name} - Level {character.level} {character.role}</h3>
            <div className="flex space-x-4">
              <span className="flex items-center"><Star className="mr-1 text-yellow-500" /> Brand: {character.brand}</span>
              <span className="flex items-center"><DollarSign className="mr-1 text-green-500" /> Money: ${character.money}</span>
              <span className="flex items-center"><TrendingUp className="mr-1 text-blue-500" /> Energy: {character.energy}%</span>
              <span className="flex items-center"><Trophy className="mr-1 text-purple-500" /> Game Tokens: {character.gameTokens}</span>
            </div>
          </div>
          
          {currentEvent && (
            <div className="bg-gray-100 p-4 rounded">
              <h4 className="font-semibold mb-2">{currentEvent.title}</h4>
              <p className="mb-4">{currentEvent.description}</p>
              <div className="space-y-2">
                {currentEvent.choices.map((choice, index) => (
                  <button
                    key={index}
                    onClick={() => handleChoice(choice)}
                    className="w-full text-left p-2 bg-white rounded hover:bg-gray-200 transition"
                  >
                    {choice.text}
                  </button>
                ))}
              </div>
            </div>
          )}
          
          <div className="bg-gray-100 p-4 rounded h-40 overflow-y-auto">
            <h4 className="font-semibold mb-2">Game Log</h4>
            {gameLog.map((log, index) => (
              <p key={index} className="text-sm">{log}</p>
            ))}
          </div>

          <div className="bg-purple-100 p-4 rounded">
            <h4 className="font-semibold mb-2 flex items-center">
              <Gift className="mr-2" /> Achievements
            </h4>
            <div className="space-y-2">
              {achievements.map(achievement => (
                <div key={achievement.id} className={`flex justify-between items-center p-2 rounded ${achievement.unlocked ? 'bg-green-200' : 'bg-gray-200'}`}>
                  <span>{achievement.name}</span>
                  <span>{achievement.unlocked ? 'Unlocked' : 'Locked'}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-blue-100 p-4 rounded">
            <h4 className="font-semibold mb-2 flex items-center">
              <Trophy className="mr-2" /> Leaderboard
            </h4>
            <div className="space-y-2">
              {leaderboard.map((entry, index) => (
                <div key={index} className="flex justify-between items-center p-2 bg-white rounded">
                  <span>{index + 1}. {entry.name}</span>
                  <span>{Math.floor(entry.score)} points</span>
                </div>
              ))}
            </div>
          </div>

          <div className="flex space-x-4">
            <button
              onClick={convertTokens}
              className="flex-1 bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 transition duration-300 flex items-center justify-center"
            >
              <DollarSign className="mr-2" />
              Convert Game Tokens
            </button>
            <button
              onClick={saveGame}
              className="flex-1 bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition duration-300 flex items-center justify-center"
            >
              <Save className="mr-2" />
              Save Game
            </button>
            <button
              onClick={loadGame}
              className="flex-1 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300 flex items-center justify-center"
            >
              <Upload className="mr-2" />
              Load Game
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdultIndustryRPG;