import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TrendingUp, TrendingDown, DollarSign, Coins, Building2, Gem, Banknote, BarChart3, CircleDollarSign } from 'lucide-react';
import { MarketData, MarketType } from '../types/market';

interface TickerProps {
  data: MarketData[];
  marketType: MarketType;
  speed?: number;
  color: string;
  icon: React.ReactNode;
}

const TickerLine: React.FC<TickerProps> = ({ data, marketType, speed = 30, color, icon }) => {
  const tickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tickerRef.current) {
      const width = tickerRef.current.scrollWidth;
      tickerRef.current.style.setProperty('--scroll-width', `-${width}px`);
    }
  }, [data]);

  return (
    <div className={`bg-gray-800 border-b border-gray-700 text-white p-2 overflow-hidden`}>
      <div className="relative">
        <div ref={tickerRef} className="flex animate-scroll" style={{ animationDuration: `${speed}s` }}>
          {data.concat(data).map((item, index) => (
            <div key={`${item.symbol}-${index}`} className="flex items-center mr-8 whitespace-nowrap">
              <span className={`mr-2 ${color}`}>{icon}</span>
              <span className="font-bold">{item.symbol}</span>
              <span className="ml-2">{item.price.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: item.price < 1 ? 8 : 2
              })}</span>
              <span className={`ml-2 ${item.change >= 0 ? 'text-green-500' : 'text-red-500'} flex items-center`}>
                {item.change >= 0 ? <TrendingUp size={16} className="mr-1" /> : <TrendingDown size={16} className="mr-1" />}
                {Math.abs(item.change).toFixed(4)}%
              </span>
              <span className="ml-2 text-xs text-gray-400">
                {new Date(item.lastUpdate).toLocaleTimeString(undefined, {
                  hour12: false,
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  fractionalSecondDigits: 3
                })}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const FinanceTicker: React.FC = () => {
  const [marketData, setMarketData] = useState<Record<MarketType, MarketData[]>>({
    crypto: [],
    stocks: [],
    forex: [],
    commodities: [],
    indices: [],
    bonds: []
  });

  const generateMarketData = useCallback((baseValues: Record<string, number>, volatility: number) => {
    const timestamp = Date.now();
    return Object.entries(baseValues).map(([symbol, basePrice]) => {
      const noise = Math.sin(timestamp / 100) * 0.001 + 
                   Math.cos(timestamp / 50) * 0.0005 +
                   Math.sin(timestamp / 25) * 0.00025;
      const change = noise * volatility * basePrice;
      
      return {
        symbol,
        price: basePrice + change,
        change: (change / basePrice) * 100,
        lastUpdate: timestamp
      };
    });
  }, []);

  const updateAllMarkets = useCallback(() => {
    const baseValues = {
      crypto: {
        'BTC/USD': 65000,
        'ETH/USD': 3500,
        'BNB/USD': 450,
        'SOL/USD': 120,
        'DOT/USD': 20
      },
      stocks: {
        'AAPL': 175,
        'GOOGL': 2800,
        'MSFT': 380,
        'AMZN': 3400,
        'NVDA': 850
      },
      forex: {
        'EUR/USD': 1.18,
        'GBP/USD': 1.35,
        'JPY/USD': 0.0088,
        'AUD/USD': 0.74,
        'CAD/USD': 0.79
      },
      commodities: {
        'GOLD': 2100,
        'SILVER': 25,
        'OIL': 85,
        'NAT.GAS': 3.5,
        'COPPER': 4.2
      },
      indices: {
        'S&P500': 4800,
        'NASDAQ': 16000,
        'DOW': 36000,
        'FTSE100': 7500,
        'DAX': 15800
      },
      bonds: {
        'US10Y': 4.25,
        'US30Y': 4.45,
        'DE10Y': 2.35,
        'UK10Y': 4.15,
        'JP10Y': 0.75
      }
    };

    const volatilities = {
      crypto: 0.0002,
      stocks: 0.0001,
      forex: 0.00005,
      commodities: 0.00015,
      indices: 0.0001,
      bonds: 0.00002
    };

    setMarketData({
      crypto: generateMarketData(baseValues.crypto, volatilities.crypto),
      stocks: generateMarketData(baseValues.stocks, volatilities.stocks),
      forex: generateMarketData(baseValues.forex, volatilities.forex),
      commodities: generateMarketData(baseValues.commodities, volatilities.commodities),
      indices: generateMarketData(baseValues.indices, volatilities.indices),
      bonds: generateMarketData(baseValues.bonds, volatilities.bonds)
    });
  }, [generateMarketData]);

  useEffect(() => {
    const interval = setInterval(updateAllMarkets, 100);
    return () => clearInterval(interval);
  }, [updateAllMarkets]);

  return (
    <div className="font-mono text-sm">
      <TickerLine
        data={marketData.crypto}
        marketType="crypto"
        speed={25}
        color="text-yellow-500"
        icon={<Coins size={16} />}
      />
      <TickerLine
        data={marketData.stocks}
        marketType="stocks"
        speed={30}
        color="text-blue-500"
        icon={<Building2 size={16} />}
      />
      <TickerLine
        data={marketData.commodities}
        marketType="commodities"
        speed={35}
        color="text-yellow-600"
        icon={<Gem size={16} />}
      />
      <TickerLine
        data={marketData.forex}
        marketType="forex"
        speed={28}
        color="text-green-500"
        icon={<Banknote size={16} />}
      />
      <TickerLine
        data={marketData.indices}
        marketType="indices"
        speed={32}
        color="text-purple-500"
        icon={<BarChart3 size={16} />}
      />
      <TickerLine
        data={marketData.bonds}
        marketType="bonds"
        speed={40}
        color="text-orange-500"
        icon={<CircleDollarSign size={16} />}
      />
    </div>
  );
};

export default FinanceTicker;