import React from 'react';
import PortfolioTracker from '../components/PortfolioTracker';
import ROICalculator from '../components/ROICalculator';
import { Link } from 'react-router-dom';
import { DollarSign, TrendingUp, AlertCircle } from 'lucide-react';
import SubscriptionManager from '../components/SubscriptionManager';
import FranchiseDashboard from '../components/FranchiseDashboard';
import FraudDetectionSystem from '../components/FraudDetectionSystem';
import LoyaltyProgram from '../components/LoyaltyProgram';
import WhiteLabelGamification from '../components/WhiteLabelGamification';

const UserDashboard: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold mb-8">Your Investment Dashboard</h1>
      
      <PortfolioTracker />
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        <ROICalculator />
        <div>
          <h2 className="text-2xl font-semibold mb-4">Quick Actions</h2>
          <div className="space-y-4">
            <Link to="/investment" className="block bg-purple-600 text-white p-4 rounded-lg hover:bg-purple-700 transition duration-300">
              <DollarSign className="inline-block mr-2" />
              Explore New Investments
            </Link>
            <Link to="/model-funding" className="block bg-green-600 text-white p-4 rounded-lg hover:bg-green-700 transition duration-300">
              <TrendingUp className="inline-block mr-2" />
              Fund a Model
            </Link>
            <Link to="/risk-assessment" className="block bg-yellow-600 text-white p-4 rounded-lg hover:bg-yellow-700 transition duration-300">
              <AlertCircle className="inline-block mr-2" />
              Risk Assessment
            </Link>
          </div>
        </div>
      </div>

      <SubscriptionManager />
      
      <div className="mt-12">
        <FranchiseDashboard />
      </div>

      <div className="mt-12">
        <FraudDetectionSystem />
      </div>

      <div className="mt-12">
        <LoyaltyProgram />
      </div>

      <div className="mt-12">
        <WhiteLabelGamification />
      </div>
    </div>
  );
};

export default UserDashboard;