import React, { useState } from 'react';
import { Image, Plus } from 'lucide-react';
import NFTListingForm from './NFTListingForm';

interface NFT {
  id: string;
  title: string;
  creator: string;
  price: number;
  image: string;
}

const NFTMarketplace: React.FC = () => {
  const [showListingForm, setShowListingForm] = useState(false);
  const [nfts, setNfts] = useState<NFT[]>([
    {
      id: '1',
      title: 'Exclusive Content NFT #1',
      creator: 'Creator XYZ',
      price: 0.5,
      image: 'https://via.placeholder.com/200x200?text=NFT+1',
    },
    // Add more initial NFTs here
  ]);

  const handleListNFT = (nftData: any) => {
    const newNFT: NFT = {
      id: (nfts.length + 1).toString(),
      title: nftData.title,
      creator: 'Current User', // This should be the actual user's name
      price: nftData.price,
      image: URL.createObjectURL(nftData.image),
    };
    setNfts([...nfts, newNFT]);
    setShowListingForm(false);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md mb-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold flex items-center">
          <Image className="text-purple-600 mr-2" />
          NFT Marketplace
        </h2>
        <button
          onClick={() => setShowListingForm(!showListingForm)}
          className="bg-purple-600 text-white px-4 py-2 rounded-full hover:bg-purple-700 transition duration-300 flex items-center"
        >
          <Plus size={20} className="mr-2" />
          List NFT
        </button>
      </div>
      
      {showListingForm && (
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">List Your NFT</h3>
          <NFTListingForm onSubmit={handleListNFT} />
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {nfts.map((nft) => (
          <div key={nft.id} className="border p-4 rounded-lg">
            <img
              src={nft.image}
              alt={nft.title}
              className="w-full h-48 object-cover rounded-lg mb-2"
            />
            <h3 className="font-semibold">{nft.title}</h3>
            <p className="text-sm text-gray-600 mb-2">By {nft.creator}</p>
            <div className="flex justify-between items-center">
              <span className="font-bold">{nft.price} ETH</span>
              <button className="bg-purple-600 text-white px-4 py-2 rounded-full hover:bg-purple-700 transition duration-300">
                Buy Now
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NFTMarketplace;