import React from 'react';
import { Mail, Star, TrendingUp, DollarSign, ExternalLink, Briefcase, Target } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import Advertisement from '../components/Advertisements';

const NewsletterPage: React.FC = () => {
  const features = [
    {
      icon: <Star className="text-yellow-500" />,
      title: "Empowerment & Growth",
      description: "Transform your passion into prosperity with expert guidance and proven strategies."
    },
    {
      icon: <TrendingUp className="text-green-500" />,
      title: "Investment Insights",
      description: "Stay ahead with cutting-edge investment opportunities and wealth-building strategies."
    },
    {
      icon: <Briefcase className="text-blue-500" />,
      title: "Business Portfolio",
      description: "Learn how to diversify and grow your business ventures for long-term success."
    },
    {
      icon: <Target className="text-purple-500" />,
      title: "Financial Freedom",
      description: "Take control of your financial journey with practical, actionable advice."
    }
  ];

  const testimonials = [
    {
      quote: "Gabrielle's insights have been instrumental in helping me build a diverse investment portfolio.",
      author: "Sarah M.",
      role: "Entrepreneur"
    },
    {
      quote: "The newsletter provides invaluable guidance for anyone serious about financial freedom.",
      author: "Michael R.",
      role: "Investor"
    }
  ];

  return (
    <>
      <Helmet>
        <title>Gabrielle Growth Newsletter - Where Passion Meets Prosperity</title>
        <meta name="description" content="Join the Gabrielle Growth Newsletter for expert investment strategies, business insights, and wealth-building opportunities. Your gateway to empowerment and financial freedom." />
        <meta name="keywords" content="investment newsletter, business growth, financial freedom, wealth building, entrepreneurship, investment strategies" />
        <meta property="og:title" content="Gabrielle Growth Newsletter - Where Passion Meets Prosperity" />
        <meta property="og:description" content="Your gateway to empowerment, investment, and infinite potential. Join thousands of visionaries building their legacy." />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://studiomystique.net/newsletter" />
      </Helmet>

      <div className="container mx-auto px-4 py-12">
        <Advertisement type="banner" />

        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <h1 className="text-4xl md:text-5xl font-bold mb-4 gradient-text">
              Where Passion Meets Prosperity
            </h1>
            <p className="text-xl text-gray-600 mb-6">
              Your Gateway to Empowerment, Investment, and Infinite Potential
            </p>
            <div className="max-w-3xl mx-auto mb-8">
              <p className="text-lg text-gray-700 leading-relaxed">
                Welcome to the Gabrielle Growth Newsletter, your trusted source for investment strategies, business insights, and the tools to achieve financial freedom. Stay ahead with the latest news on wealth-building opportunities, actionable tips for growing your business portfolio, and insider updates on innovative ventures shaping the future of entrepreneurship.
              </p>
            </div>
            <a
              href="https://gabriellegrowth-newsletter.beehiiv.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center bg-purple-600 text-white px-8 py-4 rounded-full hover:bg-purple-700 transition duration-300 text-lg font-semibold"
            >
              Start Building Your Legacy <ExternalLink className="ml-2" size={20} />
            </a>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
            {features.map((feature, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
                <div className="text-3xl mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>

          <div className="bg-purple-50 p-8 rounded-lg mb-12">
            <h2 className="text-2xl font-bold mb-6">Weekly Newsletter Benefits</h2>
            <ul className="space-y-4">
              <li className="flex items-start">
                <Mail className="text-purple-600 mt-1 mr-3" />
                <div>
                  <h3 className="font-semibold">Curated Investment Opportunities</h3>
                  <p className="text-gray-600">Discover vetted investment opportunities and wealth-building strategies.</p>
                </div>
              </li>
              <li className="flex items-start">
                <Star className="text-purple-600 mt-1 mr-3" />
                <div>
                  <h3 className="font-semibold">Expert Business Insights</h3>
                  <p className="text-gray-600">Learn from industry leaders and successful entrepreneurs.</p>
                </div>
              </li>
              <li className="flex items-start">
                <TrendingUp className="text-purple-600 mt-1 mr-3" />
                <div>
                  <h3 className="font-semibold">Market Trends & Analysis</h3>
                  <p className="text-gray-600">Stay informed about emerging opportunities and market movements.</p>
                </div>
              </li>
              <li className="flex items-start">
                <DollarSign className="text-purple-600 mt-1 mr-3" />
                <div>
                  <h3 className="font-semibold">Financial Freedom Strategies</h3>
                  <p className="text-gray-600">Practical advice for building sustainable wealth and achieving your goals.</p>
                </div>
              </li>
            </ul>
          </div>

          <div className="mb-12">
            <h2 className="text-2xl font-bold mb-6 text-center">Success Stories</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {testimonials.map((testimonial, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                  <p className="text-gray-600 italic mb-4">"{testimonial.quote}"</p>
                  <div>
                    <p className="font-semibold">{testimonial.author}</p>
                    <p className="text-sm text-gray-500">{testimonial.role}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="text-center">
            <a
              href="https://gabriellegrowth-newsletter.beehiiv.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center bg-purple-600 text-white px-8 py-4 rounded-full hover:bg-purple-700 transition duration-300 text-lg font-semibold"
            >
              Subscribe to Gabrielle Growth <ExternalLink className="ml-2" size={20} />
            </a>
            <p className="text-sm text-gray-500 mt-4">
              Join thousands of visionaries already building their legacy
            </p>
          </div>
        </div>

        <Advertisement type="float" />
      </div>
    </>
  );
};

export default NewsletterPage;