import React from 'react';
import { Tag, Check } from 'lucide-react';

const DomainSellSection: React.FC = () => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Implement domain listing logic
    console.log('Submitting domain listing');
  };

  return (
    <div className="mt-12 bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 flex items-center">
        <Tag className="mr-2" /> Sell Your Domain
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h3 className="text-xl font-semibold mb-4">Why Sell With Us?</h3>
          <ul className="space-y-2">
            <li className="flex items-center">
              <Check className="text-green-500 mr-2" />
              Access to premium adult industry buyers
            </li>
            <li className="flex items-center">
              <Check className="text-green-500 mr-2" />
              Secure escrow services
            </li>
            <li className="flex items-center">
              <Check className="text-green-500 mr-2" />
              Professional domain valuation
            </li>
            <li className="flex items-center">
              <Check className="text-green-500 mr-2" />
              Fast and secure transactions
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-xl font-semibold mb-4">List Your Domain</h3>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-2">Domain Name</label>
              <input
                type="text"
                className="w-full p-2 border rounded-lg"
                placeholder="Enter your domain name"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Asking Price</label>
              <input
                type="number"
                className="w-full p-2 border rounded-lg"
                placeholder="Enter your asking price"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Description</label>
              <textarea
                className="w-full p-2 border rounded-lg"
                rows={4}
                placeholder="Describe your domain and its potential"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-purple-600 text-white px-6 py-2 rounded-lg hover:bg-purple-700 transition duration-300"
            >
              List Domain
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DomainSellSection;