import React, { useState, useEffect } from 'react';
import { Gift, Star, ShoppingBag } from 'lucide-react';

interface Reward {
  id: string;
  name: string;
  description: string;
  pointsCost: number;
  type: 'content' | 'chat' | 'merchandise';
}

const LoyaltyProgram: React.FC = () => {
  const [userPoints, setUserPoints] = useState(0);
  const [rewards, setRewards] = useState<Reward[]>([]);

  useEffect(() => {
    // In a real application, fetch user points and rewards from an API
    setUserPoints(1500);
    setRewards([
      { id: '1', name: 'Exclusive Photo Set', description: 'Get access to an exclusive photo set', pointsCost: 500, type: 'content' },
      { id: '2', name: 'Private Chat Session', description: '15-minute private chat with a model', pointsCost: 1000, type: 'chat' },
      { id: '3', name: 'Studio Mystique T-Shirt', description: 'Limited edition Studio Mystique merchandise', pointsCost: 2000, type: 'merchandise' },
    ]);
  }, []);

  const redeemReward = (reward: Reward) => {
    if (userPoints >= reward.pointsCost) {
      setUserPoints(userPoints - reward.pointsCost);
      // In a real application, call an API to process the redemption
      alert(`You've redeemed: ${reward.name}`);
    } else {
      alert('Not enough points to redeem this reward');
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md mb-8">
      <h2 className="text-2xl font-semibold mb-4 flex items-center">
        <Gift className="mr-2 text-purple-600" /> Loyalty Program
      </h2>
      <div className="mb-6 flex items-center justify-between bg-purple-100 p-4 rounded-lg">
        <span className="text-lg font-semibold">Your Points:</span>
        <span className="text-2xl font-bold text-purple-600 flex items-center">
          <Star className="mr-2" /> {userPoints}
        </span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {rewards.map((reward) => (
          <div key={reward.id} className="border p-4 rounded-lg">
            <h3 className="font-semibold mb-2">{reward.name}</h3>
            <p className="text-sm text-gray-600 mb-4">{reward.description}</p>
            <div className="flex justify-between items-center">
              <span className="font-bold text-purple-600">{reward.pointsCost} points</span>
              <button
                onClick={() => redeemReward(reward)}
                className="bg-purple-600 text-white px-4 py-2 rounded-full hover:bg-purple-700 transition duration-300 flex items-center"
              >
                <ShoppingBag className="mr-2" size={16} />
                Redeem
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LoyaltyProgram;