import React from 'react';
import { Globe } from 'lucide-react';
import DomainSearch from '../components/domains/DomainSearch';
import DomainListings from '../components/domains/DomainListings';
import DomainSellSection from '../components/domains/DomainSellSection';
import Advertisement from '../components/Advertisements';

const DomainMarketplacePage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 flex items-center">
        <Globe className="mr-2" /> Domain Marketplace
      </h1>

      {/* Top Banner Ad */}
      <Advertisement type="banner" />

      {/* Search Section */}
      <DomainSearch />

      {/* Domain Listings */}
      <DomainListings />

      {/* Sell Your Domain Section */}
      <DomainSellSection />

      {/* Float Ad */}
      <Advertisement type="float" />
    </div>
  );
};

export default DomainMarketplacePage;