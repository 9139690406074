import React from 'react';
import { Instagram, Star, Camera, Briefcase, Users, Globe, Headphones } from 'lucide-react';
import Advertisement from '../components/Advertisements';
import FinanceTicker from '../components/FinanceTicker';
import ContactForm from '../components/ContactForm';
import ImageCarousel from '../components/ImageCarousel';
import MusicPlayer from '../components/MusicPlayer';
import ServiceBox from '../components/home/ServiceBox';
import ActionButton from '../components/home/ActionButton';
import WelcomeSection from '../components/home/WelcomeSection';
import IntroSection from '../components/home/IntroSection';
import InfoSection from '../components/home/InfoSection';

const HomePage: React.FC = () => {
  const serviceBoxes = [
    {
      title: "Social Media Management",
      path: "/services/social-media",
      icon: <Instagram className="w-8 h-8 mr-3 text-pink-400" />,
      description: "Expert social media management for adult content creators",
      features: ["Content strategy", "Community engagement", "Analytics tracking"]
    },
    {
      title: "OnlyFans Management",
      path: "/services/onlyfans",
      icon: <Camera className="w-8 h-8 mr-3 text-blue-400" />,
      description: "Complete OnlyFans account management and growth",
      features: ["Content scheduling", "Fan engagement", "Revenue optimization"]
    },
    {
      title: "Webcam Management",
      path: "/services/webcam",
      icon: <Globe className="w-8 h-8 mr-3 text-green-400" />,
      description: "Professional webcam studio management services",
      features: ["Stream optimization", "Technical support", "Performance tracking"]
    }
  ];

  const actionButtons = [
    {
      to: "/investment",
      icon: <Star />,
      title: "Invest",
      description: "Explore unique investment opportunities in the adult entertainment industry."
    },
    {
      to: "/adult-content",
      icon: <Camera />,
      title: "Watch",
      description: "Enjoy premium adult content from top creators and models."
    },
    {
      to: "/model-signup",
      icon: <Users />,
      title: "Create",
      description: "Start your journey as a content creator and grow your brand."
    }
  ];

  const newcomerInfo = {
    title: "For Newcomers: Entry into the Adult Industry",
    content: [
      "At Studio Mystique, we understand that entering the adult entertainment industry can be both exciting and challenging. We provide a safe, supportive environment where new adult models and content creators can establish their personal brand and adult career with confidence. Our tailored services include professional photoshoots, legal support, and marketing strategies designed to grow your fan base.",
      "Whether you want to become an OnlyFans influencer, or expand your brand on platforms like Pornhub, we are here to help you navigate the adult industry and develop your content with safety and success."
    ]
  };

  const veteranInfo = {
    title: "For Veterans: Transitioning Out of the Adult Industry",
    content: [
      "At Studio Mystique, we also recognize the importance of planning for a career beyond the adult entertainment industry. Our Exit Strategy Program provides financial planning, business investments, and brand diversification to support your transition. Whether you're looking to invest in real estate, laundromats, cryptocurrency, or launch a fashion brand, our team is here to help.",
      "Through our adult industry exit program, we assist you in diversifying your income streams, offering guidance on how to invest your earnings wisely in ventures such as NFTs, coins, or private equity opportunities. We're committed to helping you achieve long-term financial stability while securing a successful exit from the industry."
    ]
  };

  const fanInfo = {
    title: "For Fans: Premium Adult Entertainment Experience",
    content: [
      "Discover an unparalleled adult entertainment experience at Studio Mystique. Our premium platform offers exclusive content from top-tier adult performers, models, and creators. Engage with your favorite content creators through live streams, private chats, and custom content requests. Our VIP membership provides priority access to new releases, behind-the-scenes footage, and exclusive events.",
      "Experience adult entertainment reimagined with high-quality production values, innovative interactive features, and a sophisticated viewing environment. Join our community of discerning fans who appreciate premium adult content, intimate fan experiences, and direct creator connections."
    ]
  };

  const investorInfo = {
    title: "For Investors: Adult Industry Investment Opportunities",
    content: [
      "Studio Mystique offers sophisticated investors unique opportunities in the lucrative adult entertainment sector. Our investment portfolio includes high-yield adult platforms, content monetization systems, and innovative adult tech startups. We specialize in adult industry real estate investments, including studios, clubs, and entertainment venues, alongside traditional business opportunities like laundromats and car washes.",
      "Our investment strategies combine the high growth potential of adult entertainment with stable, recession-resistant business models. Whether you're interested in cryptocurrency tokens, NFTs, or traditional equity investments, our expert team provides comprehensive market analysis and risk management solutions for optimal portfolio performance."
    ]
  };

  const businessInfo = {
    title: "For the Discerning Business Professional",
    content: [
      "Studio Mystique caters to sophisticated business professionals seeking to capitalize on the adult entertainment industry's exponential growth. Our business development services include turnkey adult platform solutions, white-label technology implementations, and strategic consulting for adult industry ventures. We specialize in helping established businesses expand into the adult sector while maintaining professional discretion and corporate compliance.",
      "From adult payment processing solutions to content delivery networks, we provide comprehensive business infrastructure tailored to the adult industry's unique requirements. Our professional services include regulatory compliance guidance, risk management strategies, and strategic partnership opportunities for sustainable business growth."
    ]
  };

  return (
    <div className="space-y-8">
      <WelcomeSection />
      <ImageCarousel />
      <Advertisement type="banner" />
      <IntroSection />
      <Advertisement type="display" />
      <Advertisement type="anchat" placement="home" />

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {serviceBoxes.map((service, index) => (
          <ServiceBox key={index} {...service} />
        ))}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {actionButtons.map((button, index) => (
          <ActionButton key={index} {...button} />
        ))}
      </div>

      <InfoSection {...newcomerInfo} />
      <InfoSection {...veteranInfo} />
      <InfoSection {...fanInfo} />
      <InfoSection {...investorInfo} />
      <InfoSection {...businessInfo} />

      <ContactForm />
      <FinanceTicker />
      <Advertisement type="float" />
      <MusicPlayer />
    </div>
  );
};

export default HomePage;