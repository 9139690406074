import { AdType } from '../../types/ads';

export const AD_CONFIGS = {
  anchat: [
    {
      url: 'https://t.anchat.link/350091/7031?bo=2753,2754,2755,2756&popUnder=true&aff_sub5=SF_006OG000004lmDN',
      placement: ['home', 'adult-content']
    },
    {
      url: 'https://t.ajrkm.link/350091/8780/0?bo=2779,2778,2777,2776,2775&po=6533&aff_sub5=SF_006OG000004lmDN',
      placement: ['investment', 'model-signup']
    },
    {
      url: 'https://t.ajrkm.link/350091/6224/0?bo=2779,2778,2777,2776,2775&po=6533&aff_sub5=SF_006OG000004lmDN',
      placement: ['forum', 'shop']
    },
    {
      url: 'https://t.ajrkm.link/350091/9690/0?bo=2779,2778,2777,2776,2775&po=6533&aff_sub5=SF_006OG000004lmDN',
      placement: ['newsletter', 'about']
    },
    {
      url: 'https://t.amyfc.link/350091/779/0?bo=2779,2778,2777,2776,2775&po=6533&aff_sub5=SF_006OG000004lmDN',
      placement: ['live-cams']
    },
    {
      url: 'https://t.ajrkm.link/350091/7012?bo=2779,2778,2777,2776,2775&popUnder=true&aff_sub5=SF_006OG000004lmDN',
      placement: ['global']
    },
    {
      url: 'https://t.ajrkm.link/350091/6313/0?bo=2779,2778,2777,2776,2775&po=6533&aff_sub5=SF_006OG000004lmDN',
      placement: ['model-tokens']
    },
    {
      url: 'https://t.ajump.link/350091/9029/0?aff_sub5=SF_006OG000004lmDN',
      placement: ['domains']
    },
    {
      url: 'https://t.ajump.link/350091/9403/0?aff_sub5=SF_006OG000004lmDN',
      placement: ['game']
    }
  ]
};

export const getAdsByPlacement = (type: AdType, placement: string) => {
  return AD_CONFIGS[type]?.filter(ad => 
    ad.placement.includes(placement) || ad.placement.includes('global')
  ) || [];
};