import React, { useEffect } from 'react';

interface AdScriptProps {
  src: string;
  async?: boolean;
  id?: string;
  'data-cfasync'?: string;
}

const AdScript: React.FC<AdScriptProps> = ({ src, async = true, id, 'data-cfasync': cfasync }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = src;
    script.async = async;
    if (id) script.id = id;
    if (cfasync) script.setAttribute('data-cfasync', cfasync);

    document.body.appendChild(script);

    return () => {
      const existingScript = document.getElementById(id || src);
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, [src, async, id, cfasync]);

  return null;
};

export default AdScript;