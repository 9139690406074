import React from 'react';
import { Filter } from 'lucide-react';

interface VideoFiltersProps {
  filters: {
    platform: string;
    category: string;
    duration: string;
    date: string;
  };
  onChange: (filters: VideoFiltersProps['filters']) => void;
}

const VideoFilters: React.FC<VideoFiltersProps> = ({ filters, onChange }) => {
  const platforms = ['All', 'Pornhub', 'XVideos', 'Custom'];
  const categories = ['All', 'Amateur', 'Professional', 'Verified'];
  const durations = ['All', 'Short (<5min)', 'Medium (5-20min)', 'Long (>20min)'];
  const dates = ['All', 'Today', 'This Week', 'This Month', 'This Year'];

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <div className="flex items-center mb-4">
        <Filter className="text-purple-600 mr-2" />
        <h3 className="font-semibold">Filters</h3>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Platform
          </label>
          <select
            value={filters.platform}
            onChange={(e) => onChange({ ...filters, platform: e.target.value })}
            className="w-full rounded-lg border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
          >
            {platforms.map((platform) => (
              <option key={platform} value={platform.toLowerCase()}>
                {platform}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Category
          </label>
          <select
            value={filters.category}
            onChange={(e) => onChange({ ...filters, category: e.target.value })}
            className="w-full rounded-lg border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
          >
            {categories.map((category) => (
              <option key={category} value={category.toLowerCase()}>
                {category}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Duration
          </label>
          <select
            value={filters.duration}
            onChange={(e) => onChange({ ...filters, duration: e.target.value })}
            className="w-full rounded-lg border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
          >
            {durations.map((duration) => (
              <option key={duration} value={duration.toLowerCase()}>
                {duration}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Upload Date
          </label>
          <select
            value={filters.date}
            onChange={(e) => onChange({ ...filters, date: e.target.value })}
            className="w-full rounded-lg border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
          >
            {dates.map((date) => (
              <option key={date} value={date.toLowerCase()}>
                {date}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default VideoFilters;