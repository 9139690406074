import React from 'react';
import { ExternalLink } from 'lucide-react';

const IndustryResources: React.FC = () => {
  const resources = [
    { name: 'OnlyFans', url: 'https://onlyfans.com/' },
    { name: 'FanCentro', url: 'https://fancentro.com/' },
    { name: 'CCBill', url: 'https://ccbill.com/' },
    { name: 'Segpay', url: 'https://www.segpay.com/' },
    { name: 'AdultWebLaw', url: 'https://www.adultweblaw.com/' },
    { name: 'Moz', url: 'https://moz.com/' },
  ];

  return (
    <div className="bg-white p-8 rounded-lg shadow-md mb-12">
      <h2 className="text-2xl font-bold mb-4">Industry Resources</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {resources.map((resource, index) => (
          <a
            key={index}
            href={resource.url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-purple-600 hover:text-purple-800"
          >
            <ExternalLink size={16} className="mr-2" />
            {resource.name}
          </a>
        ))}
      </div>
    </div>
  );
};

export default IndustryResources;