import React from 'react';
import { Play, Clock, Eye, Calendar } from 'lucide-react';

interface Video {
  id: string;
  title: string;
  description: string;
  thumbnail: string;
  duration: string;
  views: number;
  platform: string;
  embedUrl: string;
  categories: string[];
  tags: string[];
  uploadDate: string;
}

interface VideoListProps {
  videos: Video[];
}

const VideoList: React.FC<VideoListProps> = ({ videos }) => {
  return (
    <div className="space-y-4">
      {videos.map((video) => (
        <div
          key={video.id}
          className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition duration-300"
        >
          <div className="flex">
            <div className="relative w-64 flex-shrink-0">
              <img
                src={video.thumbnail}
                alt={video.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity flex items-center justify-center">
                <Play className="text-white w-12 h-12" />
              </div>
              <div className="absolute bottom-2 right-2 bg-black bg-opacity-75 text-white px-2 py-1 rounded text-sm flex items-center">
                <Clock className="w-4 h-4 mr-1" />
                {video.duration}
              </div>
            </div>
            <div className="p-4 flex-grow">
              <h3 className="font-semibold mb-2">{video.title}</h3>
              <p className="text-gray-600 text-sm mb-4 line-clamp-2">
                {video.description}
              </p>
              <div className="flex items-center justify-between text-sm text-gray-600">
                <div className="flex items-center space-x-4">
                  <span className="flex items-center">
                    <Eye className="w-4 h-4 mr-1" />
                    {video.views.toLocaleString()} views
                  </span>
                  <span className="flex items-center">
                    <Calendar className="w-4 h-4 mr-1" />
                    {new Date(video.uploadDate).toLocaleDateString()}
                  </span>
                </div>
                <span className="bg-gray-100 px-2 py-1 rounded">
                  {video.platform}
                </span>
              </div>
              <div className="mt-2 flex flex-wrap gap-2">
                {video.categories.map((category, index) => (
                  <span
                    key={index}
                    className="bg-purple-100 text-purple-800 text-xs px-2 py-1 rounded"
                  >
                    {category}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default VideoList;