import React, { useState } from 'react';
import { User, Camera, Upload, Check } from 'lucide-react';

interface FormData {
  name: string;
  email: string;
  socialProfiles: {
    instagram: string;
    twitter: string;
    onlyfans: string;
  };
  measurements: {
    height: string;
    weight: string;
    bustWaistHip: string;
  };
  hairColor: string;
  eyeColor: string;
  tattoosPiercings: string;
  experience: string;
  interests: string[];
  contentPreferences: string[];
  stdTestResults: File | null;
  idVerification: File | null;
  agreeTerms: boolean;
}

const ModelSignUpPage: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    socialProfiles: {
      instagram: '',
      twitter: '',
      onlyfans: '',
    },
    measurements: {
      height: '',
      weight: '',
      bustWaistHip: '',
    },
    hairColor: '',
    eyeColor: '',
    tattoosPiercings: '',
    experience: '',
    interests: [],
    contentPreferences: [],
    stdTestResults: null,
    idVerification: null,
    agreeTerms: false,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSocialProfileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      socialProfiles: { ...formData.socialProfiles, [name]: value },
    });
  };

  const handleMeasurementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      measurements: { ...formData.measurements, [name]: value },
    });
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, field: 'stdTestResults' | 'idVerification') => {
    if (e.target.files && e.target.files[0]) {
      setFormData({ ...formData, [field]: e.target.files[0] });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Here you would typically send the form data to your backend
    console.log('Form submitted:', formData);
    // Implement form submission logic here
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Model Sign Up</h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <section className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Personal Information</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                required
              />
            </div>
          </div>
        </section>

        <section className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Social Media Profiles</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label htmlFor="instagram" className="block text-sm font-medium text-gray-700">Instagram</label>
              <input
                type="text"
                id="instagram"
                name="instagram"
                value={formData.socialProfiles.instagram}
                onChange={handleSocialProfileChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label htmlFor="twitter" className="block text-sm font-medium text-gray-700">Twitter</label>
              <input
                type="text"
                id="twitter"
                name="twitter"
                value={formData.socialProfiles.twitter}
                onChange={handleSocialProfileChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label htmlFor="onlyfans" className="block text-sm font-medium text-gray-700">OnlyFans</label>
              <input
                type="text"
                id="onlyfans"
                name="onlyfans"
                value={formData.socialProfiles.onlyfans}
                onChange={handleSocialProfileChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
        </section>

        <section className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Physical Attributes</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label htmlFor="height" className="block text-sm font-medium text-gray-700">Height</label>
              <input
                type="text"
                id="height"
                name="height"
                value={formData.measurements.height}
                onChange={handleMeasurementChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="e.g., 5'8&quot;"
              />
            </div>
            <div>
              <label htmlFor="weight" className="block text-sm font-medium text-gray-700">Weight</label>
              <input
                type="text"
                id="weight"
                name="weight"
                value={formData.measurements.weight}
                onChange={handleMeasurementChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="e.g., 130 lbs"
              />
            </div>
            <div>
              <label htmlFor="bustWaistHip" className="block text-sm font-medium text-gray-700">Bust/Waist/Hip</label>
              <input
                type="text"
                id="bustWaistHip"
                name="bustWaistHip"
                value={formData.measurements.bustWaistHip}
                onChange={handleMeasurementChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="e.g., 34-26-36"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
            <div>
              <label htmlFor="hairColor" className="block text-sm font-medium text-gray-700">Hair Color</label>
              <input
                type="text"
                id="hairColor"
                name="hairColor"
                value={formData.hairColor}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label htmlFor="eyeColor" className="block text-sm font-medium text-gray-700">Eye Color</label>
              <input
                type="text"
                id="eyeColor"
                name="eyeColor"
                value={formData.eyeColor}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label htmlFor="tattoosPiercings" className="block text-sm font-medium text-gray-700">Tattoos/Piercings</label>
              <input
                type="text"
                id="tattoosPiercings"
                name="tattoosPiercings"
                value={formData.tattoosPiercings}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
        </section>

        <section className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Experience & Interests</h2>
          <div>
            <label htmlFor="experience" className="block text-sm font-medium text-gray-700">Experience in the industry</label>
            <textarea
              id="experience"
              name="experience"
              value={formData.experience}
              onChange={handleInputChange}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            ></textarea>
          </div>
        </section>

        <section className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Content Preferences</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {['Solo', 'Girl/Girl', 'Boy/Girl', 'Fetish', 'BDSM', 'Group'].map((preference) => (
              <div key={preference} className="flex items-center">
                <input
                  type="checkbox"
                  id={preference}
                  name="contentPreferences"
                  value={preference}
                  checked={formData.contentPreferences.includes(preference)}
                  onChange={(e) => {
                    const updatedPreferences = e.target.checked
                      ? [...formData.contentPreferences, preference]
                      : formData.contentPreferences.filter((p) => p !== preference);
                    setFormData({ ...formData, contentPreferences: updatedPreferences });
                  }}
                  className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <label htmlFor={preference} className="ml-2 block text-sm text-gray-900">
                  {preference}
                </label>
              </div>
            ))}
          </div>
        </section>

        <section className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Verification</h2>
          <div className="space-y-4">
            <div>
              <label htmlFor="stdTestResults" className="block text-sm font-medium text-gray-700">Upload Latest STD Test Results</label>
              <input
                type="file"
                id="stdTestResults"
                name="stdTestResults"
                onChange={(e) => handleFileUpload(e, 'stdTestResults')}
                className="mt-1 block w-full text-sm text-gray-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-violet-50 file:text-violet-700
                hover:file:bg-violet-100"
              />
            </div>
            <div>
              <label htmlFor="idVerification" className="block text-sm font-medium text-gray-700">ID Verification</label>
              <input
                type="file"
                id="idVerification"
                name="idVerification"
                onChange={(e) => handleFileUpload(e, 'idVerification')}
                className="mt-1 block w-full text-sm text-gray-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-violet-50 file:text-violet-700
                hover:file:bg-violet-100"
              />
            </div>
          </div>
        </section>

        <section className="bg-white p-6 rounded-lg shadow">
          <div className="flex items-center">
            <input
              type="checkbox"
              id="agreeTerms"
              name="agreeTerms"
              checked={formData.agreeTerms}
              onChange={(e) => setFormData({ ...formData, agreeTerms: e.target.checked })}
              className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
            <label htmlFor="agreeTerms" className="ml-2 block text-sm text-gray-900">
              I agree to the terms and conditions, privacy policy, and content guidelines
            </label>
          </div>
          <p className="mt-2 text-xs text-gray-500">
            By agreeing, you acknowledge that Studio Mystique obtains full publishing rights for all content uploaded to the platform. This includes the right to use such content for marketing purposes. Please review our full terms for more details.
          </p>
        </section>

        <div className="flex justify-end">
          <button
            type="submit"
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Submit Application
          </button>
        </div>
      </form>
    </div>
  );
};

export default ModelSignUpPage;