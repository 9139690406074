import React, { useState } from 'react';
import { DollarSign, TrendingUp, Building, Car, Coins, Image, Briefcase, ChevronDown } from 'lucide-react';
import Advertisement from '../components/Advertisements';
import InvestmentCard from '../components/InvestmentCard';
import InvestmentChart from '../components/InvestmentChart';
import NFTDigitalAssets from '../components/NFTDigitalAssets';
import PrivateEquity from '../components/PrivateEquity';
import TraditionalBusinesses from '../components/TraditionalBusinesses';
import RealEstateInvesting from '../components/RealEstateInvesting';
import CrowdfundingOpportunities from '../components/CrowdfundingOpportunities';
import FinancialNews from '../components/FinancialNews';
import PortfolioDiversification from '../components/PortfolioDiversification';
import ModelFunding from '../components/ModelFunding';
import ROICalculator from '../components/ROICalculator';
import InvestorTestimonial from '../components/InvestorTestimonial';
import LegalDisclaimer from '../components/LegalDisclaimer';
import LiveChat from '../components/LiveChat';
import AIInvestmentAdvisor from '../components/AIInvestmentAdvisor';
import FinanceTicker from '../components/FinanceTicker';

const InvestmentPage: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>('all');

  const categories = [
    { id: 'all', name: 'All Opportunities', icon: <DollarSign /> },
    { id: 'digital', name: 'Digital Assets', icon: <Coins /> },
    { id: 'traditional', name: 'Traditional Business', icon: <Building /> },
    { id: 'private', name: 'Private Equity', icon: <Briefcase /> },
    { id: 'real-estate', name: 'Real Estate', icon: <Building /> },
    { id: 'model', name: 'Model Funding', icon: <Image /> }
  ];

  const investmentOpportunities = [
    {
      id: '1',
      title: 'Laundromat Chain Expansion',
      description: 'Invest in our growing chain of modern, efficient laundromats located in high-traffic areas.',
      minInvestment: 25000,
      expectedReturn: '15-20% annually',
      type: 'traditional',
      risk: 'Low',
      icon: <Building />
    },
    {
      id: '2',
      title: 'Premium Car Wash Network',
      description: 'Join our premium car wash network offering top-tier services to discerning customers.',
      minInvestment: 50000,
      expectedReturn: '18-25% annually',
      type: 'traditional',
      risk: 'Low',
      icon: <Car />
    },
    // Add more investment opportunities...
  ];

  const filteredOpportunities = selectedCategory === 'all' 
    ? investmentOpportunities 
    : investmentOpportunities.filter(opp => opp.type === selectedCategory);

  return (
    <div className="container mx-auto px-4 py-12">
      {/* Top Banner Ad */}
      <Advertisement type="banner" />

      <div className="bg-gradient-to-r from-purple-800 to-pink-800 text-white p-8 rounded-lg shadow-md mb-8">
        <h1 className="text-4xl font-bold mb-4">Explore Unique Investment Opportunities</h1>
        <p className="text-xl mb-6">
          From cutting-edge NFTs and cryptocurrency to reliable, income-generating businesses like laundromats
          and car washes, we offer a diverse range of investment opportunities to help you build wealth.
        </p>
        <button 
          onClick={() => document.getElementById('opportunities')?.scrollIntoView({ behavior: 'smooth' })}
          className="bg-white text-purple-800 px-6 py-3 rounded-full hover:bg-gray-100 transition duration-300"
        >
          Start Investing Now
        </button>
      </div>

      <FinanceTicker />

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-12">
        <div className="lg:col-span-2">
          <InvestmentChart />
        </div>
        <div>
          <AIInvestmentAdvisor />
        </div>
      </div>

      <div id="opportunities" className="mb-12">
        <div className="flex flex-wrap gap-4 mb-8">
          {categories.map(category => (
            <button
              key={category.id}
              onClick={() => setSelectedCategory(category.id)}
              className={`flex items-center px-4 py-2 rounded-full ${
                selectedCategory === category.id
                  ? 'bg-purple-600 text-white'
                  : 'bg-gray-200 hover:bg-gray-300'
              }`}
            >
              {category.icon}
              <span className="ml-2">{category.name}</span>
            </button>
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredOpportunities.map(opportunity => (
            <InvestmentCard key={opportunity.id} opportunity={opportunity} />
          ))}
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12">
        <NFTDigitalAssets />
        <PrivateEquity />
      </div>

      <TraditionalBusinesses />
      <RealEstateInvesting />
      <CrowdfundingOpportunities />
      <ModelFunding />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12">
        <ROICalculator />
        <div>
          <h3 className="text-2xl font-semibold mb-4">Investor Testimonials</h3>
          <div className="space-y-4">
            <InvestorTestimonial
              name="John D."
              testimonial="I've seen incredible returns on my laundromat investments with Studio Mystique."
              investment="Laundromat Chain"
            />
            <InvestorTestimonial
              name="Sarah M."
              testimonial="The Model Career Fund has been a game-changer for my portfolio."
              investment="Model Career Fund"
            />
          </div>
        </div>
      </div>

      <PortfolioDiversification />
      <FinancialNews />
      <LegalDisclaimer />
      <LiveChat />

      {/* Float Ad */}
      <Advertisement type="float" />
    </div>
  );
};

export default InvestmentPage;