import React from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { MessageSquare, Image, Users, TrendingUp } from 'lucide-react';
import ForumDiscussions from '../components/forum/ForumDiscussions.tsx';
import ForumGallery from '../components/forum/ForumGallery.tsx';
import ForumMembers from '../components/forum/ForumMembers.tsx';
import ForumActivity from '../components/forum/ForumActivity.tsx';
import Advertisement from '../components/Advertisements';

const ForumPage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 flex items-center">
        <MessageSquare className="mr-2" /> Studio Mystique Community
      </h1>

      {/* Top Banner Ad */}
      <Advertisement type="banner" />

      <Tabs defaultValue="discussions" className="space-y-6">
        <TabsList className="mb-4">
          <TabsTrigger value="discussions" className="flex items-center">
            <MessageSquare className="mr-2" size={16} /> Discussions
          </TabsTrigger>
          <TabsTrigger value="gallery" className="flex items-center">
            <Image className="mr-2" size={16} /> Gallery
          </TabsTrigger>
          <TabsTrigger value="members" className="flex items-center">
            <Users className="mr-2" size={16} /> Members
          </TabsTrigger>
          <TabsTrigger value="activity" className="flex items-center">
            <TrendingUp className="mr-2" size={16} /> Activity
          </TabsTrigger>
        </TabsList>

        <TabsContent value="discussions">
          <ForumDiscussions />
        </TabsContent>

        <TabsContent value="gallery">
          <ForumGallery />
        </TabsContent>

        <TabsContent value="members">
          <ForumMembers />
        </TabsContent>

        <TabsContent value="activity">
          <ForumActivity />
        </TabsContent>
      </Tabs>

      {/* Float Ad */}
      <Advertisement type="float" />
    </div>
  );
};

export default ForumPage;