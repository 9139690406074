import React from 'react';
import { Link } from 'react-router-dom';

const PrivateEquity: React.FC = () => {
  return (
    <div className="bg-white p-8 rounded-lg shadow-md mb-12">
      <h2 className="text-3xl font-bold mb-4 text-purple-800">Private Equity for Exclusive Access</h2>
      <p className="text-lg mb-4">
        Gain access to high-potential, long-term investment opportunities in the adult entertainment industry and beyond.
      </p>
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Featured Opportunities</h3>
        <ul className="list-disc list-inside">
          <li>Adult Entertainment Growth Fund</li>
          <li>Studio Expansion Projects</li>
          <li>Tech Startups in Adult Industry</li>
        </ul>
      </div>
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Key Features</h3>
        <ul className="list-disc list-inside">
          <li>Minimum Investment: $50,000</li>
          <li>Expected ROI: 15-25% annually</li>
          <li>Quarterly performance reports</li>
          <li>Priority access to new investment rounds</li>
        </ul>
      </div>
      <Link to="/private-equity" className="bg-purple-600 text-white px-6 py-3 rounded-full hover:bg-purple-700 transition duration-300">
        Invest in Private Equity
      </Link>
    </div>
  );
};

export default PrivateEquity;