import React, { useEffect } from 'react';
import { handleAdError } from '../../utils/errorHandling';
import { getAdsByPlacement } from './AdConfig';

interface AnChatAdProps {
  placement?: string;
}

const AnChatAd: React.FC<AnChatAdProps> = ({ placement = 'global' }) => {
  useEffect(() => {
    const loadAnChatScripts = async () => {
      try {
        const ads = getAdsByPlacement('anchat', placement);
        
        const scripts = ads.map(ad => {
          const script = document.createElement('script');
          script.src = ad.url;
          script.async = true;
          script.defer = true;
          script.onerror = () => handleAdError(new Error(`AnChat script failed to load: ${ad.url}`), 'anchat');
          return script;
        });

        scripts.forEach(script => document.body.appendChild(script));
        
        return () => {
          scripts.forEach(script => {
            if (script.parentNode) {
              script.parentNode.removeChild(script);
            }
          });
        };
      } catch (error) {
        handleAdError(error as Error, 'anchat');
      }
    };

    loadAnChatScripts();
  }, [placement]);

  return null;
};

export default AnChatAd;