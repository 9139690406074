import React, { useState } from 'react';
import DomainCard from './DomainCard';
import { Domain } from '../../types/domain';

const DomainListings: React.FC = () => {
  const [domains] = useState<Domain[]>([
    {
      id: '1',
      name: 'adultcontent.com',
      price: 25000,
      category: 'adult',
      listed: true,
      traffic: 5000,
      age: 15,
      seller: 'DomainPro',
      description: 'Premium adult domain with established traffic'
    },
    {
      id: '2',
      name: 'modelagency.com',
      price: 15000,
      category: 'business',
      listed: true,
      traffic: 3000,
      age: 10,
      seller: 'DomainInvestor',
      description: 'Perfect for model management businesses'
    },
    {
      id: '3',
      name: 'adultstore.com',
      price: 35000,
      category: 'adult',
      listed: true,
      traffic: 7500,
      age: 20,
      seller: 'PremiumDomains',
      description: 'High-value adult e-commerce domain'
    }
  ]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
      {domains.map(domain => (
        <DomainCard key={domain.id} domain={domain} />
      ))}
    </div>
  );
};

export default DomainListings;