import React from 'react';
import { Link } from 'react-router-dom';

const TraditionalBusinesses: React.FC = () => {
  return (
    <div className="bg-white p-8 rounded-lg shadow-md mb-12">
      <h2 className="text-3xl font-bold mb-4 text-purple-800">Invest in Profitable Businesses</h2>
      <p className="text-lg mb-4">
        Diversify your portfolio with solid, income-generating traditional businesses. These investments offer stability and consistent returns.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-6">
        <div>
          <h3 className="text-xl font-semibold mb-2">Laundromats</h3>
          <p className="mb-4">Invest in our growing chain of modern, efficient laundromats located in high-traffic areas.</p>
          <ul className="list-disc list-inside mb-4">
            <li>Multiple locations across major cities</li>
            <li>State-of-the-art equipment</li>
            <li>Eco-friendly operations</li>
          </ul>
        </div>
        <div>
          <h3 className="text-xl font-semibold mb-2">Car Washes</h3>
          <p className="mb-4">Be part of our premium car wash network, offering top-tier services to discerning customers.</p>
          <ul className="list-disc list-inside mb-4">
            <li>Automated and hand-wash options</li>
            <li>Loyalty programs for recurring revenue</li>
            <li>Expanding to new locations</li>
          </ul>
        </div>
      </div>
      <Link to="/traditional-businesses" className="bg-purple-600 text-white px-6 py-3 rounded-full hover:bg-purple-700 transition duration-300">
        View Business Opportunities
      </Link>
    </div>
  );
};

export default TraditionalBusinesses;