import React, { useEffect, useRef } from 'react';
import { Chart, ChartConfiguration } from 'chart.js/auto';

const InvestmentChart: React.FC = () => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        // Destroy existing chart if it exists
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }

        // Create new chart
        const config: ChartConfiguration = {
          type: 'line',
          data: {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
            datasets: [
              {
                label: 'NFT Investments',
                data: [12, 19, 3, 5, 2, 3],
                borderColor: 'rgb(255, 99, 132)',
                tension: 0.1
              },
              {
                label: 'Crypto Investments',
                data: [7, 11, 5, 8, 3, 7],
                borderColor: 'rgb(54, 162, 235)',
                tension: 0.1
              }
            ]
          },
          options: {
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: 'Investment Performance'
              }
            }
          }
        };

        chartInstance.current = new Chart(ctx, config);
      }
    }

    // Cleanup function to destroy the chart when component unmounts
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  return (
    <div className="mb-12">
      <h3 className="text-2xl font-semibold mb-4">Investment Performance</h3>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default InvestmentChart;