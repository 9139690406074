import React, { useState } from 'react';
import { ChevronRight, ChevronLeft, X } from 'lucide-react';

interface AudienceQuestionnaireProps {
  onComplete: () => void;
}

const AudienceQuestionnaire: React.FC<AudienceQuestionnaireProps> = ({ onComplete }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    interests: [] as string[],
    role: '',
    investmentRange: '',
    experience: '',
    goals: [] as string[]
  });

  const interests = [
    'Content Creation',
    'Investing',
    'Adult Entertainment',
    'Business Development',
    'Technology',
    'Marketing',
    'Real Estate',
    'Cryptocurrency'
  ];

  const roles = [
    'Content Creator',
    'Investor',
    'Business Owner',
    'Fan/Supporter',
    'Model',
    'Industry Professional'
  ];

  const handleSkip = () => {
    localStorage.setItem('questionnaire_skipped', 'true');
    onComplete();
  };

  const canProceed = () => {
    switch (step) {
      case 1:
        return formData.interests.length > 0;
      case 2:
        return formData.role !== '';
      case 3:
        return formData.investmentRange !== '';
      case 4:
        return formData.goals.length > 0;
      default:
        return false;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg max-w-2xl w-full mx-4 relative">
        {/* Close/Skip Button */}
        <button
          onClick={handleSkip}
          className="absolute top-4 right-4 p-2 hover:bg-gray-100 rounded-full transition-colors"
          aria-label="Skip questionnaire"
        >
          <X className="w-6 h-6 text-gray-500" />
        </button>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-2">Welcome to Studio Mystique</h2>
          <p className="text-gray-600">Help us personalize your experience ({step}/4)</p>
          <button
            onClick={handleSkip}
            className="text-purple-600 hover:text-purple-700 text-sm mt-2"
          >
            Skip Questionnaire
          </button>
        </div>

        <div className="mb-8">
          {step === 1 && (
            <div>
              <h3 className="font-semibold mb-4">What are your interests?</h3>
              <div className="grid grid-cols-2 gap-2">
                {interests.map(interest => (
                  <button
                    key={interest}
                    onClick={() => setFormData(prev => ({
                      ...prev,
                      interests: prev.interests.includes(interest)
                        ? prev.interests.filter(i => i !== interest)
                        : [...prev.interests, interest]
                    }))}
                    className={`p-3 rounded-lg text-left ${
                      formData.interests.includes(interest)
                        ? 'bg-purple-100 text-purple-700 border-2 border-purple-500'
                        : 'bg-gray-100 hover:bg-gray-200'
                    }`}
                  >
                    {interest}
                  </button>
                ))}
              </div>
            </div>
          )}

          {step === 2 && (
            <div>
              <h3 className="font-semibold mb-4">What best describes your role?</h3>
              <div className="grid grid-cols-2 gap-2">
                {roles.map(role => (
                  <button
                    key={role}
                    onClick={() => setFormData(prev => ({ ...prev, role }))}
                    className={`p-3 rounded-lg text-left ${
                      formData.role === role
                        ? 'bg-purple-100 text-purple-700 border-2 border-purple-500'
                        : 'bg-gray-100 hover:bg-gray-200'
                    }`}
                  >
                    {role}
                  </button>
                ))}
              </div>
            </div>
          )}

          {step === 3 && (
            <div>
              <h3 className="font-semibold mb-4">What's your investment range?</h3>
              <div className="space-y-2">
                {['$0-$1,000', '$1,000-$10,000', '$10,000-$50,000', '$50,000+'].map(range => (
                  <button
                    key={range}
                    onClick={() => setFormData(prev => ({ ...prev, investmentRange: range }))}
                    className={`w-full p-3 rounded-lg text-left ${
                      formData.investmentRange === range
                        ? 'bg-purple-100 text-purple-700 border-2 border-purple-500'
                        : 'bg-gray-100 hover:bg-gray-200'
                    }`}
                  >
                    {range}
                  </button>
                ))}
              </div>
            </div>
          )}

          {step === 4 && (
            <div>
              <h3 className="font-semibold mb-4">What are your goals?</h3>
              <div className="space-y-2">
                {[
                  'Generate Income',
                  'Build a Business',
                  'Invest for Future',
                  'Network with Industry Professionals',
                  'Learn New Skills',
                  'Find Opportunities'
                ].map(goal => (
                  <button
                    key={goal}
                    onClick={() => setFormData(prev => ({
                      ...prev,
                      goals: prev.goals.includes(goal)
                        ? prev.goals.filter(g => g !== goal)
                        : [...prev.goals, goal]
                    }))}
                    className={`w-full p-3 rounded-lg text-left ${
                      formData.goals.includes(goal)
                        ? 'bg-purple-100 text-purple-700 border-2 border-purple-500'
                        : 'bg-gray-100 hover:bg-gray-200'
                    }`}
                  >
                    {goal}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-between items-center">
          {step > 1 && (
            <button
              onClick={() => setStep(step - 1)}
              className="flex items-center text-purple-600 hover:text-purple-700"
            >
              <ChevronLeft className="w-5 h-5 mr-1" />
              Back
            </button>
          )}
          
          {step < 4 ? (
            <button
              onClick={() => setStep(step + 1)}
              disabled={!canProceed()}
              className={`flex items-center ml-auto ${
                canProceed()
                  ? 'text-purple-600 hover:text-purple-700'
                  : 'text-gray-400 cursor-not-allowed'
              }`}
            >
              Next
              <ChevronRight className="w-5 h-5 ml-1" />
            </button>
          ) : (
            <button
              onClick={() => {
                localStorage.setItem('questionnaire_completed', 'true');
                localStorage.setItem('questionnaire_data', JSON.stringify(formData));
                onComplete();
              }}
              disabled={!canProceed()}
              className={`px-6 py-2 rounded-lg ml-auto ${
                canProceed()
                  ? 'bg-purple-600 text-white hover:bg-purple-700'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
            >
              Complete
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AudienceQuestionnaire;