import React from 'react';
import { ExternalLink, Video, Users, User, Heart } from 'lucide-react';

const ChaturbateSection: React.FC = () => {
  const affiliateLinks = [
    { name: 'All Cams', url: 'https://chaturbate.com/in/?tour=ZLaY&campaign=O6mYm&track=default', icon: <Video /> },
    { name: 'Female Cams', url: 'https://chaturbate.com/in/?tour=wFE6&campaign=O6mYm&track=default', icon: <Heart /> },
    { name: 'Male Cams', url: 'https://chaturbate.com/in/?tour=EyB0&campaign=O6mYm&track=default', icon: <User /> },
    { name: 'Couple Cams', url: 'https://chaturbate.com/in/?tour=3u46&campaign=O6mYm&track=default', icon: <Users /> },
    { name: 'Trans Cams', url: 'https://chaturbate.com/in/?tour=xC0v&campaign=O6mYm&track=default', icon: <User /> },
  ];

  return (
    <div className="bg-white p-6 rounded-lg shadow-md mb-8">
      <h2 className="text-2xl font-semibold mb-4 flex items-center">
        <Video className="text-red-500 mr-2" />
        Live Cams on Chaturbate
      </h2>
      <p className="mb-4">
        Experience live adult entertainment through our Chaturbate white label. Watch and interact with performers in real-time!
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
        {affiliateLinks.map((link, index) => (
          <a 
            key={index}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-red-600 text-white px-4 py-2 rounded-full hover:bg-red-700 transition duration-300 inline-flex items-center justify-center"
          >
            {React.cloneElement(link.icon, { size: 20, className: 'mr-2' })}
            {link.name}
            <ExternalLink size={16} className="ml-2" />
          </a>
        ))}
      </div>
      <a 
        href="https://studiomystique.chaturbate.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-purple-600 text-white px-6 py-3 rounded-full hover:bg-purple-700 transition duration-300 inline-flex items-center"
      >
        Visit Studio Mystique on Chaturbate
        <ExternalLink size={20} className="ml-2" />
      </a>
    </div>
  );
};

export default ChaturbateSection;