import React from 'react';
import AdScript from './AdScript';

interface FloatAdProps {
  adId: string;
}

const FloatAd: React.FC<FloatAdProps> = ({ adId }) => {
  return (
    <div>
      <script
        dangerouslySetInnerHTML={{
          __html: `juicy_adzone = '${adId}';`
        }}
      />
      <AdScript src="https://poweredby.jads.co/js/jfc.js" id={`jfc-script-${adId}`} />
    </div>
  );
};

export default FloatAd;