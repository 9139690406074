import React from 'react';
import { DollarSign, TrendingUp, AlertTriangle } from 'lucide-react';

interface InvestmentOpportunity {
  id: string;
  title: string;
  description: string;
  minInvestment: number;
  expectedReturn: string;
  type: string;
  risk: 'Low' | 'Medium' | 'High';
  icon: React.ReactNode;
}

interface InvestmentCardProps {
  opportunity: InvestmentOpportunity;
}

const InvestmentCard: React.FC<InvestmentCardProps> = ({ opportunity }) => {
  const getRiskColor = (risk: string) => {
    switch (risk) {
      case 'Low':
        return 'text-green-600';
      case 'Medium':
        return 'text-yellow-600';
      case 'High':
        return 'text-red-600';
      default:
        return 'text-gray-600';
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition duration-300">
      <div className="flex items-center mb-4">
        <div className="bg-purple-100 p-2 rounded-full mr-4">
          {opportunity.icon}
        </div>
        <h3 className="text-xl font-semibold">{opportunity.title}</h3>
      </div>

      <p className="text-gray-600 mb-4">{opportunity.description}</p>

      <div className="space-y-2 mb-4">
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-500">Minimum Investment</span>
          <span className="font-semibold flex items-center">
            <DollarSign size={16} className="mr-1" />
            {opportunity.minInvestment.toLocaleString()}
          </span>
        </div>

        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-500">Expected Return</span>
          <span className="font-semibold flex items-center text-green-600">
            <TrendingUp size={16} className="mr-1" />
            {opportunity.expectedReturn}
          </span>
        </div>

        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-500">Risk Level</span>
          <span className={`font-semibold flex items-center ${getRiskColor(opportunity.risk)}`}>
            <AlertTriangle size={16} className="mr-1" />
            {opportunity.risk}
          </span>
        </div>
      </div>

      <button className="w-full bg-purple-600 text-white py-2 px-4 rounded-lg hover:bg-purple-700 transition duration-300 flex items-center justify-center">
        <DollarSign size={16} className="mr-2" />
        Invest Now
      </button>
    </div>
  );
};

export default InvestmentCard;