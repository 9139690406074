import React from 'react';
import DisplayAd from './DisplayAd';
import FloatAd from './FloatAd';
import NativeAd from './NativeAd';
import AnChatAd from './AnChatAd';

interface AdManagerProps {
  type: 'display' | 'float' | 'native' | 'anchat';
  placement?: string;
}

const AdManager: React.FC<AdManagerProps> = ({ type, placement }) => {
  // Generate unique ad ID based on type and placement
  const adId = `${type}-${placement || 'default'}-${Math.random().toString(36).substr(2, 9)}`;

  const renderAd = () => {
    switch (type) {
      case 'display':
        return <DisplayAd adId={adId} width={728} height={90} />;
      case 'float':
        return <FloatAd adId={adId} />;
      case 'native':
        return <NativeAd adId={adId} />;
      case 'anchat':
        return <AnChatAd />;
      default:
        return null;
    }
  };

  return (
    <div className="ad-container" data-ad-type={type} data-placement={placement}>
      {renderAd()}
    </div>
  );
};

export default AdManager;