import React from 'react';

interface InfoSectionProps {
  title: string;
  content: string[];
}

const InfoSection: React.FC<InfoSectionProps> = ({ title, content }) => {
  return (
    <div className="bg-white p-8 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">{title}</h2>
      {content.map((paragraph, index) => (
        <p key={index} className="text-gray-600 mb-4">{paragraph}</p>
      ))}
    </div>
  );
};

export default InfoSection;