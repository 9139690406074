import React from 'react';
import AdScript from './AdScript';

interface DisplayAdProps {
  adId: string;
  width: number;
  height: number;
}

const DisplayAd: React.FC<DisplayAdProps> = ({ adId, width, height }) => {
  return (
    <div className="flex justify-center my-4">
      <AdScript src="https://poweredby.jads.co/js/jads.js" id={`jads-script-${adId}`} />
      <ins
        className="juicy-ad"
        id={adId}
        data-width={width}
        data-height={height}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `(adsbyjuicy = window.adsbyjuicy || []).push({'adzone':${adId}});`
        }}
      />
    </div>
  );
};

export default DisplayAd;