import React, { useEffect, useState } from 'react';
import AdManager from './ads/AdManager';
import { isAdBlockerEnabled } from '../utils/errorHandling';

interface AdvertisementProps {
  type: 'display' | 'float' | 'native' | 'anchat';
  placement?: string;
}

const Advertisement: React.FC<AdvertisementProps> = ({ type, placement }) => {
  const [adBlockerDetected, setAdBlockerDetected] = useState(false);

  useEffect(() => {
    const checkAdBlocker = async () => {
      const isEnabled = await isAdBlockerEnabled();
      setAdBlockerDetected(isEnabled);
    };
    checkAdBlocker();
  }, []);

  if (adBlockerDetected) {
    return (
      <div className="ad-blocker-notice text-center p-4 bg-gray-100 rounded">
        <p className="text-sm text-gray-600">
          Please consider disabling your ad blocker to support our content.
        </p>
      </div>
    );
  }

  return <AdManager type={type} placement={placement} />;
};

export default Advertisement;