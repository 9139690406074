import React from 'react';
import { Video } from 'lucide-react';
import ChaturbateSection from '../components/ChaturbateSection';

const LiveCamsPage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 flex items-center">
        <Video className="mr-2 text-red-500" size={32} />
        Live Cams
      </h1>
      <ChaturbateSection />
    </div>
  );
};

export default LiveCamsPage;