import React from 'react';

const LegalDisclaimer: React.FC = () => {
  return (
    <div className="bg-gray-100 p-6 rounded-lg mb-12 text-sm">
      <h3 className="text-lg font-semibold mb-2">Legal Disclaimer</h3>
      <p className="mb-2">
        Investing in financial instruments, including stocks, bonds, cryptocurrencies, NFTs, and alternative investments, involves risks, including the potential loss of principal. Studio Mystique does not provide personalized investment advice or recommendations.
      </p>
      <p className="mb-2">
        Past performance is not indicative of future results. The information provided on this website is for general informational purposes only and should not be considered as investment advice. Always conduct your own due diligence and consult with a licensed financial advisor before making any investment decisions.
      </p>
      <p>
        By using this website and its investment features, you acknowledge that you have read and understood this disclaimer and agree to the terms and conditions set forth by Studio Mystique.
      </p>
    </div>
  );
};

export default LegalDisclaimer;